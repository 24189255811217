/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import {Video} from '../../../common/web';

function VideoElement({track, className = ''}) {
  let videoTrack = {jitsiTrack: track};

  return (
    <>
      <Video
        key={`video_${track.getId()}`}
        id={`video_${track.getId()}`}
        videoTrack={videoTrack}
        className={className}
      />
    </>
  );
}

export default VideoElement;
