import React, {useEffect, useMemo} from 'react';
import {AttendeeName} from '../../../common/web';
import VideoElement from './videoElement';
import {useSelector} from 'react-redux';
import {
  setPresentationTracks,
  setPresentationViewParticipant,
} from '../utils/functions';

function PresentationView({showActiveWindow}) {
  const presentationParticipant = useSelector(
    state => state.conf.presentationParticipant,
  );
  const currentActiveVideoTracks = useSelector(
    state => state.conf.currentActiveVideoTracks,
  );

  const screenSharedJid = useSelector(state => state.conf.screenSharedJid);
  const audioMode = useSelector(state => state.conf.audioMode);
  const refreshVideoView = useSelector(state => state.conf.refreshVideoView);

  useEffect(() => {
    setPresentationViewParticipant(showActiveWindow);
  }, [showActiveWindow, screenSharedJid, audioMode, refreshVideoView]);

  useEffect(() => {
    setPresentationTracks(true);
  }, [currentActiveVideoTracks]);

  useEffect(() => {
    const requestTimer = setInterval(() => {
      setPresentationTracks(false, true);
    }, 60 * 1000);

    return () => {
      clearInterval(requestTimer);
    };
  }, [currentActiveVideoTracks]);

  const name = useMemo(() => {
    return presentationParticipant.name
      ? presentationParticipant.name + '&#39;s Presentation'
      : '';
  }, [presentationParticipant]);
  const videoTrack = presentationParticipant?.track;

  return (
    <div className={'videoBlock videoSpeaker  single-user-view '}>
      <div className="videoBlockInner">
        {audioMode ? (
          <>
            <span className="white stand-by">You're in Audio Only Mode</span>
          </>
        ) : videoTrack ? (
          <>
            <VideoElement track={videoTrack.jitsiTrack} />
            {name && <AttendeeName name={name} />}
          </>
        ) : (
          <video className="videoloading" />
        )}
      </div>
    </div>
  );
}

export default PresentationView;
