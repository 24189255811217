/* eslint-disable no-script-url */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable no-void */
import {
  APP_VERSION,
  BUILD_NO,
  MicCameraDisabledText,
  MicDisabledTextForGuest,
  viewType,
} from '../../../utils/util';
import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  localVideoTrackUnMute,
  localVideoTrackMute,
  localAudioTrackMute,
  localAudioTrackUnMute,
} from '../../../libs';
import {
  setUserDetail,
  setRatingPopUp,
  setAttendeesClick,
  setChatClick,
  setOpenRecordingModal,
  setExitModalShow,
  setOpenStreamManagerModal,
  setUnreadMessageCount,
  setView,
  setSpeaker,
  setOpenLoginModel,
  setMuteRequest,
  setUnmuteRequest,
  setMaxVideos,
  setWidgetClick,
  setStopScreenShareRequest,
  setStopVideoRequest,
} from '../../../reduxStore/confSlice';
import {updParticipant} from '../../../utils/socket';
import {
  btnAccess,
  handleCamera,
  handleMic,
  haveMicPermission,
  isLemMode,
} from '../../../utils/functions';
import StartRecording from './modals/startRecording';
import StopRecordingModal from './modals/stopRecording';
import {startscreenshare, stopscreenshare} from '../../../libs';
import InvitePeople from './invitePeople';
import AvSettings from './avSetting';
import EditProfile from './editProfile';
import PhoneCommand from './phoneCommand';
import WebinarSetting from './webinarSetting';
import TutorialsContact from './tutorialsContact';
import RoomSetting from './roomSetting';
import EventAndRecording from './eventAndRecording';
import RoomPermission from './roomPermission';
import Timer from './recordingTimer';
import StreamManager from './modals/social-streaming/streamManagerModal';
import AddRemoveMangeRoom from './addRemoveMangeRoom';
import {IconTick} from '../../../icons';
import UpgradeNow from './upgradeNow';
import ExitModal from './modals/exitModal';
import ReportProblemModal from './modals/reportProblemModal';
import StreamingTimer from './streamingTimer';
// import ShareWidgetContent from './shareWidgetContent';
// import AddRemoveMangeRoom from './addRemoveMangeRoom';
// import Invoice from './invoice';
// import UpgradeNow from './upgradeNow';
const ConferenceHeader = () => {
  const {
    participantsJID,
    openRecordingModal,
    openStreamManageModal,
    speaker,
    myRole,
    chatClick,
    attendeesClick,
    view,
    localUser,
    unreadMessageCount,
    openStopRecordingModal,
    roomDetail,
    screenSharedJid,

    participantsGuest,
    participantsWaiting,
    maxVideos,
    trial,
    billing,
    exitModal,
    widgetClick,
    lemAttendeesCount,
  } = useSelector(state => state.conf);
  const [waitingRoomTitle, setWaitingRoomTitle] = useState(null);
  const [waitingTitleInterval, setWaitingTitleInterval] = useState(null);
  const [waitingRoomCycle, setWaitingRoomCycle] = useState(false);
  const [reportProblem, showReportProblem] = useState(false);

  let screenShareStart = false;
  const lemMode = isLemMode();
  const dispatch = useDispatch();
  const {
    recording,
    hide_guest_count_names_for_guests,
    guests_have_extra_powers,
    guests_can_unmute_themselves,
  } = roomDetail;

  // console.log('roomDetail', roomDetail);
  // console.log('waitingRoomCycle', waitingRoomCycle);
  useEffect(() => {
    if (localUser.role !== 'guest' || guests_have_extra_powers === true) {
      if (Object.keys(participantsWaiting).length > 0) {
        setWaitingRoomCycle(true);
      } else {
        setWaitingRoomCycle(false);
        if (waitingTitleInterval !== null) {
          clearInterval(waitingTitleInterval);
          setWaitingTitleInterval(null);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participantsWaiting]);

  useEffect(() => {
    if (waitingRoomCycle && waitingTitleInterval === null) {
      let attempt = 1;
      let title = '';
      const intervalData = setInterval(() => {
        if (attempt === 1) {
          title = 'Waiting';
        } else if (attempt === 2) {
          title = 'Room';
        } else {
          title = '...';
        }
        document.title = title;
        setWaitingRoomTitle(title);
        attempt++;
        if (attempt > 3) {
          attempt = 1;
        }
      }, 1000);
      setWaitingTitleInterval(intervalData);
    } else {
      document.title = 'Meetn';
    }
  }, [waitingRoomCycle, waitingTitleInterval]);

  const handleSpeaker = e => {
    e.preventDefault();
    if (speaker) {
      dispatch(setSpeaker(false));
    } else {
      dispatch(setSpeaker(true));
    }
  };

  const screenSharedHandler = e => {
    e.preventDefault();
    if (!screenShareStart) {
      screenShareStart = true;
      const tmpUser = {...localUser};
      if (tmpUser?.screenShare) {
        stopscreenshare().then(() => {
          screenShareStart = false;
        });
      } else {
        startscreenshare().then(res => {
          if (res.screenShare) {
            tmpUser.screenShare = true;
            dispatch(setUserDetail(tmpUser));
            updParticipant(tmpUser);
          }
          screenShareStart = false;
        });
      }
    }
  };
  const handleRaise = e => {
    e.preventDefault();
    const updateLocalUser = {...localUser};
    if (!localUser.hand_raised) {
      updateLocalUser.hand_raised_timestamp = new Date();
    }
    updateLocalUser.hand_raised = !localUser.hand_raised;
    dispatch(setUserDetail(updateLocalUser));
    updParticipant(updateLocalUser);
  };

  const handleChat = e => {
    e.preventDefault();
    dispatch(setUnreadMessageCount(0));
    dispatch(setChatClick(!chatClick));
    dispatch(setAttendeesClick(false));
    dispatch(setWidgetClick(false));
  };

  const handleRecording = e => {
    e.preventDefault();
    dispatch(setOpenRecordingModal(true));
  };

  const handleStreamManager = e => {
    e.preventDefault();
    dispatch(setOpenStreamManagerModal(true));
  };

  const handleAttendees = e => {
    e.preventDefault();
    dispatch(setAttendeesClick(!attendeesClick));
    dispatch(setChatClick(false));
    dispatch(setWidgetClick(false));
  };

  const handleWidget = e => {
    e.preventDefault();
    dispatch(setWidgetClick(!widgetClick));
    dispatch(setAttendeesClick(false));
    dispatch(setChatClick(false));
  };

  const handleEndMeeting = e => {
    e.preventDefault();
    dispatch(setExitModalShow(true));
    dispatch(setRatingPopUp(true));
  };

  const manageMaxVideosView = value => {
    dispatch(setMaxVideos(value));
  };

  const handleRedirect = link => {
    window.open(link, '_blank');
  };

  // console.log('chatClick', chatClick, attendeesClick);

  return (
    <>
      <nav
        className="navbar navbar-expand-lg navbar-dark custom-nav conference-nav"
        id="conference-nav-bar">
        <div className="flex-left">
          <a href="/" className="navbar-brand">
            <img src="assets/images/logo-grey.png" alt="" />
          </a>
          {!lemMode && (
            <div className="navbarSupportedContent">
              <ul className="navbar-nav center_menu">
                <li className="nav-item dropdown MainMenuUL">
                  <a
                    href="javascript:void(0)"
                    className="nav-link mainmenu dropdown-toggle"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    role="button">
                    <span>Menu</span>
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuLink">
                    <ul className="navbar-nav center_menu btm-border-grey">
                      <li className="nav-item">
                        <InvitePeople />
                      </li>
                      <li className="nav-item">
                        <AvSettings />
                      </li>
                      <li className="nav-item">
                        <EditProfile />
                      </li>
                      <li className="nav-item">
                        <PhoneCommand />
                      </li>

                      <span className="tag">Everyone</span>
                    </ul>

                    <ul className="navbar-nav center_menu btm-border-grey">
                      <li className="nav-item">
                        <WebinarSetting />
                      </li>
                      <li className="nav-item">
                        <TutorialsContact />
                      </li>
                      <span className="tag">Hosts</span>
                    </ul>

                    <ul className="navbar-nav center_menu btm-border-grey">
                      <li className="nav-item">
                        <RoomSetting />
                      </li>
                      <li className="nav-item">
                        <EventAndRecording />
                      </li>
                      <li className="nav-item">
                        <RoomPermission />
                      </li>
                      {/* <li className="nav-item">
                      <ShareWidgetContent />
                    </li> */}
                      <span className="tag">Admins</span>
                    </ul>

                    <ul className="navbar-nav center_menu btm-border-grey">
                      {localUser?.role === 'owner' && (
                        <>
                          <li className="nav-item">
                            <AddRemoveMangeRoom />
                          </li>
                          {/* <li className="nav-item">
                      <Invoice />
                    </li> */}
                          {trial && billing && (
                            <li className="nav-item">
                              <UpgradeNow />
                            </li>
                          )}

                          <span className="tag">Owner</span>
                        </>
                      )}

                      <li className="nav-item">
                        <a className="justify-content-between cursor-default">
                          <span>
                            <small>
                              Version {APP_VERSION} ({BUILD_NO}){' '}
                            </small>
                          </span>
                          {localUser.jid && (
                            <span>
                              <small>MID:&nbsp;</small>
                              <small style={{textTransform: 'lowercase'}}>
                                {localUser.jid?.substr(0, 3)}
                              </small>
                            </span>
                          )}
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>

                <li className="nav-item">
                  <a
                    onClick={e =>
                      localUser.role !== 'guest' ||
                      roomDetail?.guests_have_extra_powers === true
                        ? handleRecording(e)
                        : dispatch(setOpenLoginModel(true))
                    }
                    className={`nav-link ${
                      recording ? 'recording-on' : 'recording-off'
                    } `}>
                    <span
                      className={`${recording ? 'record-on' : 'record-off'}`}>
                      {recording ? (
                        <>
                          {'Recording'} <br /> <Timer />
                        </>
                      ) : (
                        <>
                          {'Start'} <br /> {'Recording'}
                        </>
                      )}
                    </span>
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className={`nav-link   ${
                      roomDetail?.streaming ? 'streaming-on' : 'streaming-off'
                    }`}
                    onClick={e =>
                      localUser.role !== 'guest'
                        ? handleStreamManager(e)
                        : dispatch(setOpenLoginModel(true))
                    }>
                    <span
                      className={`${
                        roomDetail?.streaming ? 'stream-on' : 'stream-off'
                      }`}>
                      {roomDetail?.streaming ? (
                        <>
                          {'Streaming'} <br /> <StreamingTimer />
                        </>
                      ) : (
                        <>
                          Start
                          <br /> Streaming
                        </>
                      )}
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          )}
          <button
            className="btn btn-dark"
            onClick={() => showReportProblem(true)}>
            Report
            <br />
            Problem
          </button>
        </div>
        <div className="toolsWrap">
          {lemMode ? (
            <>
              <span
                className="tooltip_container tooltip_container--bottom-right tooltip_container--sm"
                data-tooltip={MicCameraDisabledText}>
                <i class={'icon-mic-disabled'} />
                Mic
              </span>
              <span
                className="tooltip_container tooltip_container--bottom-right tooltip_container--sm"
                data-tooltip={MicCameraDisabledText}>
                <i class={'icon-cam-disabled'} />
                Camera
              </span>
            </>
          ) : (
            <>
              <span
                onClick={() => {
                  !haveMicPermission()
                    ? console.log('cannot unable')
                    : handleMic();
                }}
                className={`${
                  !haveMicPermission()
                    ? 'tooltip_container tooltip_container--bottom-right tooltip_container--sm'
                    : ''
                }`}
                data-tooltip={
                  !haveMicPermission() ? MicDisabledTextForGuest : undefined
                }>
                <i
                  className={
                    localUser?.audio === true ? 'icon-mic' : 'icon-mic-disabled'
                  }
                />
                Mic
              </span>
              <span id="cutomVideoBtn" onClick={handleCamera}>
                <i
                  className={
                    localUser?.video === true ? 'icon-cam' : 'icon-cam-disabled'
                  }
                />
                Camera
              </span>
            </>
          )}

          <span className="speaker-toolbar" onClick={handleSpeaker}>
            <i
              className={
                speaker === true ? 'icon-speaker' : 'icon-speaker-disabled'
              }
            />
            Speaker
          </span>
          {roomDetail?.hide_raise_hand_for_guests && (
            <span onClick={handleRaise}>
              <i
                className={
                  localUser.hand_raised ? 'icon-raise' : 'icon-raise-disabled'
                }
              />
              Raise
            </span>
          )}

          {!lemMode && (
            <span className="dropdown view-drop">
              <a
                className={`nav-link ${
                  view === viewType.active
                    ? 'speakericon'
                    : view === viewType.presentation
                    ? 'presentationicon'
                    : 'galleryicon'
                }`}
                id="viewdrop"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                role="button"
                href="javascript:void(0)">
                <span>
                  <i className="icon-gallery" />
                  View
                </span>
              </a>
              <div className="dropdown-menu" aria-labelledby="viewdrop">
                <ul>
                  <li>
                    <a onClick={() => dispatch(setView(viewType.gallery))}>
                      {view === viewType.gallery ? <IconTick /> : ''}
                      Gallery
                    </a>
                  </li>
                  <li>
                    <a onClick={() => dispatch(setView(viewType.active))}>
                      {view === viewType.active ? <IconTick /> : ''}
                      Active Speaker, Including Yourself
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() =>
                        dispatch(setView(viewType.activeExcludingYourself))
                      }>
                      {view === viewType.activeExcludingYourself ? (
                        <IconTick />
                      ) : (
                        ''
                      )}
                      Active Speaker, Excluding Yourself
                    </a>
                  </li>
                  <li>
                    <a
                      className={
                        screenSharedJid === null
                          ? 'cursor-default disabled_content'
                          : ''
                      }
                      onClick={() => {
                        if (screenSharedJid !== null) {
                          dispatch(setView(viewType.presentation));
                        }
                      }}>
                      {view === viewType.presentation ? <IconTick /> : ''}
                      Presentation
                    </a>
                  </li>
                  <hr />
                  <li>
                    <a href={void 0} onClick={() => manageMaxVideosView(9)}>
                      {maxVideos === 9 && <IconTick />}
                      Show 9 Videos Max
                    </a>
                  </li>
                  <li>
                    <a href={void 0} onClick={() => manageMaxVideosView(16)}>
                      {maxVideos === 16 && <IconTick />}
                      Show 16 Videos Max
                    </a>
                  </li>
                  <li>
                    <a href={void 0} onClick={() => manageMaxVideosView(24)}>
                      {maxVideos === 24 && <IconTick />}
                      Show 24 Videos Max
                    </a>
                  </li>
                </ul>
              </div>
            </span>
          )}
        </div>
        <div className="flex-right">
          {roomDetail?.custom_button_text && roomDetail?.custom_button_url && (
            <button
              className="btn btn-dark custom__btn_text"
              onClick={() => handleRedirect(roomDetail.custom_button_url)}>
              {roomDetail?.custom_button_text}
            </button>
          )}
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            {localUser?.role !== 'guest' ||
            (localUser?.role === 'guest' &&
              roomDetail?.hide_share_screen_for_guests) ? (
              <li
                className={`nav-item share_screen ${
                  localUser.screenShare && 'active'
                }`}
                id="screenShareTab">
                <a
                  className={`nav-link custom-tabs presenticon  overflow-menu-item ${
                    localUser.screenShare ? 'screenShare' : ''
                  }`}
                  id="share-tab"
                  href="javascript:void(0)"
                  role="tab"
                  onClick={e => screenSharedHandler(e)}>
                  <span>
                    {localUser.screenShare ? (
                      <>
                        Stop <br />
                        Sharing
                      </>
                    ) : (
                      <>
                        Share <br />
                        Screen
                      </>
                    )}
                  </span>
                </a>
              </li>
            ) : (
              ''
            )}

            <li
              className={`nav-item ${attendeesClick === true ? 'active' : ''}`}>
              <a
                className={`nav-link custom-tabs overflow-menu-item attendees-icon-wrap ${
                  attendeesClick === true ? 'active' : ''
                }`}
                href="javascript:void(0)"
                onClick={handleAttendees}>
                <div>
                  <div
                    className={`attendees-filled ${
                      waitingRoomCycle ? 'grey-green-blink' : ''
                    }`}>
                    <small>
                      {localUser.role === 'guest' &&
                      hide_guest_count_names_for_guests &&
                      !guests_have_extra_powers
                        ? null
                        : lemMode
                        ? Object.keys(participantsJID).length +
                          1 +
                          (lemAttendeesCount > 0
                            ? lemAttendeesCount - 1
                            : lemAttendeesCount)
                        : Object.keys(participantsJID).length +
                          1 +
                          lemAttendeesCount}
                    </small>
                  </div>
                </div>

                <span>{waitingRoomCycle ? waitingRoomTitle : 'Attendees'}</span>
              </a>
            </li>
            {btnAccess('chat') ? (
              <li
                className={`nav-item mr-15px-minus ${
                  chatClick === true ? 'active' : ''
                }`}>
                <a
                  className={`nav-link custom-tabs ${
                    unreadMessageCount > 0
                      ? 'chaticon-filled chaticon-filled-blink'
                      : 'chaticon'
                  } ${chatClick === true ? 'active' : ''}`}
                  href="javascript:void(0)"
                  onClick={handleChat}>
                  <span>Chat</span>
                  {unreadMessageCount > 0 && (
                    <small>
                      {unreadMessageCount > 99 ? '99' : unreadMessageCount}
                    </small>
                  )}
                </a>
              </li>
            ) : null}

            {btnAccess('widgets') && (
              <li className={`nav-item ml-20px ${widgetClick ? 'active' : ''}`}>
                <a
                  className="nav-link custom-tabs extension-icon-wrap"
                  id="extensionstab"
                  href="javascript:void(0)"
                  onClick={handleWidget}>
                  <span>Widgets</span>
                </a>
              </li>
            )}

            <li className="nav-item">
              <a
                className="nav-link custom-tabs exit-icon-wrap"
                data-toggle="modal"
                data-target="#exit-modal"
                onClick={handleEndMeeting}>
                <span>End</span>
              </a>
            </li>
          </ul>
        </div>
      </nav>
      {openRecordingModal === true && myRole !== 'guest' ? (
        <StartRecording />
      ) : openStreamManageModal === true && myRole !== 'guest' ? (
        <StreamManager />
      ) : null}
      {openStopRecordingModal === true && <StopRecordingModal />}
      {exitModal === true && <ExitModal />}
      {reportProblem && <ReportProblemModal closeModal={showReportProblem} />}
    </>
  );
};
export default ConferenceHeader;
