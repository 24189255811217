import React, {useEffect, useMemo, useRef} from 'react';
import {useSelector} from 'react-redux';
import {
  checkParticipantsVideoStreamStatus,
  isTrackStreamingStatusActive,
  validateUnstableConnection,
} from '../../../../utils/functions';
import VideoBlockContainer from './videoBlockContainer';
import {setGalleryParticipiants, setGalleryTracks} from '../utils/functions';
import {changeTrackResolution} from '../../../../libs';

// let interval = 0;
// let intervalTime = 30 * 1000;

function GalleryView({showActiveWindow, setMp4Video}) {
  const localUser = useSelector(state => state.conf.localUser);
  const dominantSpeaker = useSelector(state => state.conf.dominantSpeaker);
  const screenDimension = useSelector(state => state.conf.screenDimension);
  const galleryViewParticipants = useSelector(
    state => state.conf.galleryViewParticipants,
  );

  const currrentPage = useSelector(state => state.conf.currrentPage);
  const maxVideos = useSelector(state => state.conf.maxVideos);
  const activeVideoParticipantIds = useSelector(
    state => state.conf.activeVideoParticipantIds,
  );
  const currentActiveVideoTracks = useSelector(
    state => state.conf.currentActiveVideoTracks,
  );
  const participantIds = useSelector(state => state.conf.participantIds);
  const screenSharedJid = useSelector(state => state.conf.screenSharedJid);
  const unmutedParticipantIds = useSelector(
    state => state.conf.unmutedParticipantIds,
  );
  const videoContainerSettings = useSelector(
    state => state.conf.videoContainerSettings,
  );
  const audioMode = useSelector(state => state.conf.audioMode);
  const trackStreamStatus = useSelector(state => state.conf.trackStreamStatus);

  const refreshVideoView = useSelector(state => state.conf.refreshVideoView);
  const firstUpdate = useRef(true);
  const tracksfirstUpdate = useRef(true);

  const {height, width} = screenDimension;

  useEffect(() => {
    const statusTimer = setTimeout(() => {
      checkParticipantsVideoStreamStatus();
    }, 5000);

    return () => {
      clearTimeout(statusTimer);
    };
  }, [trackStreamStatus]);

  useEffect(() => {
    const connectionTimer = setTimeout(() => {
      validateUnstableConnection();
    }, 30 * 1000);

    return () => {
      clearTimeout(connectionTimer);
    };
  }, [trackStreamStatus]);

  useEffect(() => {
    if (!showActiveWindow) {
      setGalleryParticipiants();
    }
  }, [
    maxVideos,
    currrentPage,
    showActiveWindow,
    videoContainerSettings?.hide_muted_attendees,
    unmutedParticipantIds,
    activeVideoParticipantIds,
    localUser,
    screenSharedJid,
    // dominantSpeaker,
    refreshVideoView,
  ]);

  useEffect(() => {
    if (!showActiveWindow) {
      let checkOnGallery = galleryViewParticipants
        .filter(filterItem => filterItem.jid !== localUser?.jid)
        .some(item => !participantIds.includes(item.jid));
      if (checkOnGallery) {
        setGalleryParticipiants();
      } else if (galleryViewParticipants.length < maxVideos) {
        setGalleryParticipiants();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participantIds]);

  useEffect(() => {
    if (tracksfirstUpdate.current) {
      tracksfirstUpdate.current = false;
      return;
    }
    setGalleryTracks(currentActiveVideoTracks, true);
  }, [currentActiveVideoTracks]);

  useEffect(() => {
    const requestTimer = setInterval(() => {
      setGalleryTracks(currentActiveVideoTracks, false, true);
    }, 60 * 1000); // 1 minute

    return () => {
      clearInterval(requestTimer);
    };
  }, [currentActiveVideoTracks]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (audioMode) {
      changeTrackResolution([], true);
    } else {
      setGalleryTracks(currentActiveVideoTracks, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioMode]);

  const _participantsList = useMemo(() => {
    return galleryViewParticipants.filter(u => u);
  }, [galleryViewParticipants]);

  return (
    <>
      {_participantsList.length > 0 ? (
        _participantsList.map(participant => {
          let speakerClass =
            participant?.jid === dominantSpeaker && _participantsList.length > 1
              ? 'highlighted-box'
              : '';
          let darkBackground = participant?.track ? '' : 'darkGrey';
          let isActive = isTrackStreamingStatusActive(
            participant?.track,
            trackStreamStatus,
          );
          // let statusInactive = !isActive && showWarning;
          // let isVideoInactive = (participant?.track && audioMode) || statusInactive ? true : false;

          let isVideoInactive = !isActive;

          return (
            <div
              className={`videoBlock ${speakerClass} ${darkBackground}`}
              style={{width: `${width}px`, height: `${height}px`}}
              key={participant.jid + '_' + participant?.type + '_partent'}>
              <VideoBlockContainer
                participant={participant}
                isVideoInactive={isVideoInactive}
                setMp4Video={setMp4Video}
              />
            </div>
          );
        })
      ) : (
        <span className="white stand-by">
          Please stand by until someone is unmuted.
        </span>
      )}
    </>
  );
}

export default GalleryView;
