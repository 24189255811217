import React, {memo, useEffect, useMemo} from 'react';
import {AttendeeName, Avatar} from '../../../common/web';
import VideoElement from './videoElement';
import {useSelector} from 'react-redux';
import {isTrackStreamingStatusActive} from '../../../../utils/functions';
import {
  sendActiveSpeakerRequest,
  sendActiveSpeakerRequestForSmallWindow,
  setActiveSmallWindowParticipant,
  setActiveSpeakerParticipant,
  setActiveTracks,
} from '../utils/functions';
import VideoElementMp4 from './VideoElementMp4';

function ActiveSpeakerView({largeWindow, showActiveWindow, setMp4Video}) {
  const localUser = useSelector(state => state.conf.localUser);
  const {mirrorSelfView} = useSelector(state => state.persist);
  const videoContainerSettings = useSelector(
    state => state.conf.videoContainerSettings,
  );
  const activeParticipant = useSelector(state => state.conf.activeParticipant);
  console.log('activeParticipant', activeParticipant);
  const trackStreamStatus = useSelector(state => state.conf.trackStreamStatus);
  const recentUnmutedUsers = useSelector(
    state => state.conf.recentUnmutedUsers,
  );
  const dominantSpeaker = useSelector(state => state.conf.dominantSpeaker);
  const audioMode = useSelector(state => state.conf.audioMode);
  const currentActiveVideoTracks = useSelector(
    state => state.conf.currentActiveVideoTracks,
  );
  const refreshVideoView = useSelector(state => state.conf.refreshVideoView);
  const videoTrack = activeParticipant?.track;
  let darkBackground = videoTrack ? '' : 'darkGrey';
  let mirrorClass =
    activeParticipant?.local && mirrorSelfView ? 'flipVideoX' : '';
  const activeVideoParticipantIds = useSelector(
    state => state.conf.activeVideoParticipantIds,
  );

  const unmutedParticipantIds = useSelector(
    state => state.conf.unmutedParticipantIds,
  );

  const screenSharedJid = useSelector(state => state.conf.screenSharedJid);

  let hideMutedAteendees = useMemo(() => {
    return videoContainerSettings?.hide_muted_attendees;
  }, [videoContainerSettings]);

  let isActive = useMemo(() => {
    return isTrackStreamingStatusActive(videoTrack?.jitsiTrack) && !audioMode;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoTrack, trackStreamStatus, audioMode]);

  useEffect(() => {
    if (showActiveWindow) {
      setActiveSmallWindowParticipant();
    } else {
      setActiveSpeakerParticipant();
    }
  }, [
    dominantSpeaker,
    hideMutedAteendees,
    recentUnmutedUsers,
    showActiveWindow,
    activeVideoParticipantIds,
    unmutedParticipantIds,
    screenSharedJid,
    localUser?.video,
    audioMode,
    refreshVideoView,
  ]);

  useEffect(() => {
    if (!showActiveWindow) {
      setActiveTracks(true);
    }
  }, [currentActiveVideoTracks, showActiveWindow]);

  useEffect(() => {
    const requestTimer = setInterval(() => {
      showActiveWindow
        ? sendActiveSpeakerRequestForSmallWindow(true)
        : sendActiveSpeakerRequest(true);
    }, 60 * 1000);

    return () => {
      clearInterval(requestTimer);
    };
  }, [activeParticipant, showActiveWindow]);

  return (
    <>
      {activeParticipant ? (
        <>
          {hideMutedAteendees ? (
            <>
              {activeParticipant?.audio === true ? (
                <div
                  className={`videoBlock videoSpeaker  ${
                    largeWindow ? 'single-user-view' : ''
                  }   ${darkBackground}`}>
                  <div className="videoBlockInner">
                    {videoTrack !== null && isActive ? (
                      <>
                        {activeParticipant?.local ? (
                          <VideoElementMp4 cb={setMp4Video} />
                        ) : (
                          <VideoElement
                            track={videoTrack.jitsiTrack}
                            className={mirrorClass}
                          />
                        )}

                        {/* When video is visible */}
                        <AttendeeName name={activeParticipant?.name} />
                        {/* When video is off */}
                      </>
                    ) : (
                      <>
                        <Avatar
                          participant={activeParticipant}
                          showWarning={!isActive}
                        />
                      </>
                    )}
                  </div>
                </div>
              ) : (
                <span
                  className={`white stand-by ${
                    !largeWindow ? 'small-view' : ''
                  }`}>
                  Please stand by until someone is unmuted.
                </span>
              )}
            </>
          ) : (
            <div
              className={`videoBlock videoSpeaker  ${
                largeWindow ? 'single-user-view' : ''
              }  ${darkBackground}`}>
              <div className="videoBlockInner">
                {videoTrack && isActive ? (
                  <>
                    {activeParticipant?.local ? (
                      <VideoElementMp4 cb={setMp4Video} />
                    ) : (
                      <VideoElement
                        track={videoTrack.jitsiTrack}
                        className={mirrorClass}
                      />
                    )}

                    {/* When video is visible */}
                    <AttendeeName name={activeParticipant?.name} />
                    {/* When video is off */}
                  </>
                ) : (
                  <>
                    <Avatar
                      participant={activeParticipant}
                      showWarning={!isActive}
                    />
                  </>
                )}
              </div>
            </div>
          )}
        </>
      ) : (
        <> </>
      )}
    </>
  );
}

export default memo(ActiveSpeakerView);
