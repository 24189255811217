import {createSlice} from '@reduxjs/toolkit';
import {SMALL_FONT_SIZE} from '../utils/util';
const initialState = {
  selectedLanguage: 'en',
  liveCaption: false,
  showLanguagePopup: false,
  transcriptData: {},
  textFontSize: SMALL_FONT_SIZE,
  liveCaptionMobile: true,
  showSpokenLanguagePopup: false,
};
export const transcriptionSlice = createSlice({
  name: 'transcription',
  initialState,
  reducers: {
    resetTranscriptionState: () => initialState,
    showLiveCaption: (state, action) => {
      state.liveCaption = action.payload;
    },
    setLanguagePopup: (state, action) => {
      state.showLanguagePopup = action.payload;
    },
    setSelectedLanguage: (state, action) => {
      state.selectedLanguage = action.payload;
    },
    setTranscriptData: (state, action) => {
      state.transcriptData = action.payload;
    },
    setTextFontSize: (state, action) => {
      state.textFontSize = action.payload;
    },
    removeTranscriptMessage: (state, action) => {
      let messages = {...state.transcriptData};
      let messageid = action.payload;

      if (messages[messageid]) {
        delete messages[messageid];
      }
      state.transcriptData = messages;
    },
    setSpokenLanguagePopup: (state, action) => {
      state.showSpokenLanguagePopup = action.payload;
    },
  },
});

export const {
  resetTranscriptionState,
  showLiveCaption,
  setLanguagePopup,
  setSelectedLanguage,
  setTranscriptData,
  setTextFontSize,
  removeTranscriptMessage,
  setSpokenLanguagePopup,
} = transcriptionSlice.actions;

export default transcriptionSlice.reducer;
