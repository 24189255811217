import React, {useMemo, useRef} from 'react';
import {useSelector} from 'react-redux';

function ActiveSmallWindow({children}) {
  const windowDimension = useSelector(state => state.conf.windowDimension);
  let {clientWidth, clientHeight} = windowDimension;
  const windowRef = useRef(null);
  const containerRef = useRef(null);

  const containerStyle = useMemo(() => {
    let windowHeight = windowRef?.current?.clientHeight ?? 0;
    let windowWidth = windowRef?.current?.clientWidth ?? 0;
    let calculatedHeight = (windowHeight * 75) / 100;
    let calculatedWidth = (windowWidth * 75) / 100;
    return {
      position: 'absolute',
      height: clientHeight + calculatedHeight,
      width: clientWidth + calculatedWidth,
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientHeight, clientWidth]);

  const windowStyle = useMemo(() => {
    return {
      position: 'fixed',
      bottom: '1em',
      right: '1em',
      zIndex: 9999,
      height: '6.9em',
      width: '12em',
      // height: '9.3em',
      // width: '16em',
      background: 'grey',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    };
  }, []);

  return (
    <>
      <div style={{position: 'absolute'}}>
        <div
          className="drag-container"
          style={containerStyle}
          ref={containerRef}>
          <div
            className="small-active-window"
            id="small-active-window"
            ref={windowRef}
            style={windowStyle}>
            {children}
          </div>
        </div>
      </div>
    </>
  );
}

export default ActiveSmallWindow;
