import React, {memo, useEffect, useMemo} from 'react';
import VideoElement from './videoElement';
import {useSelector} from 'react-redux';
import {AttendeeName, Avatar} from '../../../common/web';
import {isTrackStreamingStatusActive} from '../../../../utils/functions';
import {
  sendActiveSpeakerRequest,
  setActiveExcludingYourselfParticipant,
  setActiveTracks,
} from '../utils/functions';

function ActiveSpeakerExcluding({showActiveWindow, setMp4Video}) {
  const videoContainerSettings = useSelector(
    state => state.conf.videoContainerSettings,
  );
  const activeParticipant = useSelector(state => state.conf.activeParticipant);
  const audioMode = useSelector(state => state.conf.audioMode);
  const trackStreamStatus = useSelector(state => state.conf.trackStreamStatus);
  const recentUnmutedUsers = useSelector(
    state => state.conf.recentUnmutedUsers,
  );
  const dominantSpeaker = useSelector(state => state.conf.dominantSpeaker);
  const currentActiveVideoTracks = useSelector(
    state => state.conf.currentActiveVideoTracks,
  );
  const activeVideoParticipantIds = useSelector(
    state => state.conf.activeVideoParticipantIds,
  );
  const videoTrack = activeParticipant?.track;
  let darkBackground = videoTrack ? '' : 'darkGrey';
  const unmutedParticipantIds = useSelector(
    state => state.conf.unmutedParticipantIds,
  );

  useEffect(() => {
    if (!showActiveWindow) {
      setActiveExcludingYourselfParticipant();
    }
  }, [
    dominantSpeaker,
    videoContainerSettings?.hide_muted_attendees,
    recentUnmutedUsers,
    showActiveWindow,
    activeVideoParticipantIds,
    unmutedParticipantIds,
    audioMode,
  ]);

  useEffect(() => {
    if (!showActiveWindow) {
      setActiveTracks(currentActiveVideoTracks);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentActiveVideoTracks]);

  let isActive = useMemo(() => {
    return isTrackStreamingStatusActive(videoTrack?.jitsiTrack) && !audioMode;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoTrack, trackStreamStatus, audioMode]);
  console.log('isActive', isActive);
  useEffect(() => {
    if (!showActiveWindow) {
      const requestTimer = setInterval(() => {
        sendActiveSpeakerRequest();
      }, 60 * 1000);

      return () => {
        clearInterval(requestTimer);
      };
    }
  }, [activeParticipant, showActiveWindow]);

  return (
    <>
      {videoContainerSettings?.hide_muted_attendees ? (
        <>
          {activeParticipant.audio === true ? (
            <div
              className={`videoBlock videoSpeaker  single-user-view  ${darkBackground}`}>
              <div className="videoBlockInner">
                {videoTrack !== null && isActive ? (
                  <>
                    <VideoElement track={videoTrack.jitsiTrack} />

                    {/* When video is visible */}
                    <AttendeeName name={activeParticipant?.name} />
                    {/* When video is off */}
                  </>
                ) : (
                  <>
                    <Avatar
                      participant={activeParticipant}
                      showWarning={!isActive}
                    />
                  </>
                )}
              </div>
            </div>
          ) : (
            <span className="white stand-by">
              Please stand by until someone else is unmuted.
            </span>
          )}
        </>
      ) : (
        <>
          {activeParticipant?.name ? (
            <div
              className={`videoBlock videoSpeaker  single-user-view  ${darkBackground}`}>
              <div className="videoBlockInner">
                {videoTrack !== null && isActive ? (
                  <>
                    <VideoElement track={videoTrack.jitsiTrack} />

                    {/* When video is visible */}
                    <AttendeeName name={activeParticipant?.name} />
                    {/* When video is off */}
                  </>
                ) : (
                  <>
                    <Avatar
                      participant={activeParticipant}
                      showWarning={!isActive}
                    />
                  </>
                )}
              </div>
            </div>
          ) : (
            <span className="white stand-by">
              Please stand by until someone else is unmuted.
            </span>
          )}
        </>
      )}
    </>
  );
}

export default memo(ActiveSpeakerExcluding);
