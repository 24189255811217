/* eslint-disable react/self-closing-comp */
/* eslint-disable react/react-in-jsx-scope */

export const APP_NAME = 'Meetn';
export const APP_VERSION = '3.6.8';
export const BUILD_NO = '891';
export const validationParam = {
  email: 'email',
  password: 'password',
  name: 'name',
  room: 'room',
};

export const supportTokens = ['4d826cb903c97a4c05a4', '5d845ab903c87ad805a0'];

export const maxWaitingRoomList = 10;
export const TILE_ASPECT_RATIO = 16 / 9;
export const TenMegaBytes = 10 * 1024 * 1024;
export const viewType = {
  active: 'active',
  gallery: 'gallery',
  presentation: 'presentation',
  activeExcludingYourself: 'activeExcludingYourself',
};

export const uploadAllowedMimeType = () => {
  return [
    'video/mpeg',
    'video/x-ms-asf',
    'video/x-flv',
    'video/mp4',
    'video/MP2T',
    'video/3gpp',
    'video/quicktime',
    'video/x-msvideo',
    'video/x-ms-wmv',
    'video/avi',
    'image/webp',
    'image/gif',
    'image/jpeg',
    'image/tiff',
    'image/bmp',
    'image/png',
  ];
};

export const uploadImageMimeType = () => {
  return [
    'image/webp',
    'image/gif',
    'image/jpeg',
    'image/tiff',
    'image/bmp',
    'image/png',
  ];
};

export const timezoneArray = [
  {label: 'Pacific', value: 'PST'},
  {label: 'Mountain', value: 'MST'},
  {label: 'Central', value: 'CST'},
  {label: 'Eastern', value: 'EST'},
  {label: 'Hawaii', value: 'HST'},
  {label: '--------', value: ''},
  {label: 'British', value: 'BST'},
  {label: 'Central European', value: 'CET'},
  {label: 'Eastern European', value: 'EET'},
  {label: '--------', value: ''},
  {label: 'Australian Western', value: 'AWT'},
  {label: 'Australian Central', value: 'ACT'},
  {label: 'Australian Eastern', value: 'AET'},
  {label: 'New Zealand', value: 'NZT'},
  {label: '--------', value: ''},
  {label: 'Middle East', value: 'AST'},
  {label: 'India', value: 'IST'},
  {label: 'Japan', value: 'JST'},
  {label: 'India', value: 'IST'},
  {label: 'Singapore', value: 'SST'},
  {label: 'South Africa', value: 'EAT'},
  {label: '--------', value: ''},
  {label: 'Atlantic', value: 'ADT'},
  {label: 'Newfoundland', value: 'NST'},
];

export const durationArray = [
  {label: '15 Minutes', value: '15'},
  {label: '30 Minutes', value: '30'},
  {label: '1 Hour', value: '60'},
  {label: '2 Hours', value: '120'},
  {label: '4 Hours', value: '240'},
];

export const destionationIcons = new Map([
  [
    'youtube',
    <svg width="85%" viewBox="1.99 5 20.01 14.01">
      <path
        fill="#F50004"
        d="M21.593 7.203a2.506 2.506 0 0 0-1.762-1.766C18.265 5.007 12 5 12 5s-6.264-.007-7.831.404a2.56 2.56 0 0 0-1.766 1.778c-.413 1.566-.417 4.814-.417 4.814s-.004 3.264.406 4.814c.23.857.905 1.534 1.763 1.765 1.582.43 7.83.437 7.83.437s6.265.007 7.831-.403a2.515 2.515 0 0 0 1.767-1.763c.414-1.565.417-4.812.417-4.812s.02-3.265-.407-4.831zM9.996 15.005l.005-6 5.207 3.005-5.212 2.995z"></path>
    </svg>,
  ],
  [
    'instagram',
    <svg width="85%" className="instagram_icon" viewBox="3 2.98 18.01 18.04">
      <path d="M20.947 8.305a6.53 6.53 0 0 0-.419-2.216 4.61 4.61 0 0 0-2.633-2.633 6.606 6.606 0 0 0-2.186-.42c-.962-.043-1.267-.055-3.709-.055s-2.755 0-3.71.055a6.606 6.606 0 0 0-2.185.42 4.607 4.607 0 0 0-2.633 2.633 6.554 6.554 0 0 0-.419 2.185c-.043.963-.056 1.268-.056 3.71s0 2.754.056 3.71c.015.748.156 1.486.419 2.187a4.61 4.61 0 0 0 2.634 2.632 6.584 6.584 0 0 0 2.185.45c.963.043 1.268.056 3.71.056s2.755 0 3.71-.056a6.59 6.59 0 0 0 2.186-.419 4.615 4.615 0 0 0 2.633-2.633c.263-.7.404-1.438.419-2.187.043-.962.056-1.267.056-3.71-.002-2.442-.002-2.752-.058-3.709zm-8.953 8.297c-2.554 0-4.623-2.069-4.623-4.623s2.069-4.623 4.623-4.623a4.623 4.623 0 0 1 0 9.246zm4.807-8.339a1.077 1.077 0 0 1-1.078-1.078 1.077 1.077 0 1 1 2.155 0c0 .596-.482 1.078-1.077 1.078z"></path>
      <circle cx="11.994" cy="11.979" r="3.003"></circle>
    </svg>,
  ],
  [
    'linkedIn_page',
    <svg width="85%" viewBox="3 3 18 18">
      <path
        fill="#2577B5"
        d="M20 3H4a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zM8.339 18.337H5.667v-8.59h2.672v8.59zM7.003 8.574a1.548 1.548 0 1 1 0-3.096 1.548 1.548 0 0 1 0 3.096zm11.335 9.763h-2.669V14.16c0-.996-.018-2.277-1.388-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248h-2.667v-8.59h2.56v1.174h.037c.355-.675 1.227-1.387 2.524-1.387 2.704 0 3.203 1.778 3.203 4.092v4.71z"></path>
    </svg>,
  ],
  [
    'linkedin_profile',
    <svg width="85%" viewBox="3 3 18 18">
      <path
        fill="#2577B5"
        d="M20 3H4a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zM8.339 18.337H5.667v-8.59h2.672v8.59zM7.003 8.574a1.548 1.548 0 1 1 0-3.096 1.548 1.548 0 0 1 0 3.096zm11.335 9.763h-2.669V14.16c0-.996-.018-2.277-1.388-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248h-2.667v-8.59h2.56v1.174h.037c.355-.675 1.227-1.387 2.524-1.387 2.704 0 3.203 1.778 3.203 4.092v4.71z"></path>
    </svg>,
  ],
  [
    'linkedIn_group',
    <svg width="85%" viewBox="3 3 18 18">
      <path
        fill="#2577B5"
        d="M20 3H4a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zM8.339 18.337H5.667v-8.59h2.672v8.59zM7.003 8.574a1.548 1.548 0 1 1 0-3.096 1.548 1.548 0 0 1 0 3.096zm11.335 9.763h-2.669V14.16c0-.996-.018-2.277-1.388-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248h-2.667v-8.59h2.56v1.174h.037c.355-.675 1.227-1.387 2.524-1.387 2.704 0 3.203 1.778 3.203 4.092v4.71z"></path>
    </svg>,
  ],
  [
    'fb_group',
    <svg width="85%" viewBox="2 2 20 19.88">
      <path
        fill="#2A77F2"
        d="M12.001 2.002c-5.522 0-9.999 4.477-9.999 9.999 0 4.99 3.656 9.126 8.437 9.879v-6.988h-2.54v-2.891h2.54V9.798c0-2.508 1.493-3.891 3.776-3.891 1.094 0 2.24.195 2.24.195v2.459h-1.264c-1.24 0-1.628.772-1.628 1.563v1.875h2.771l-.443 2.891h-2.328v6.988C18.344 21.129 22 16.992 22 12.001c0-5.522-4.477-9.999-9.999-9.999z"></path>
    </svg>,
  ],
  [
    'fb_page',
    <svg width="85%" viewBox="2 2 20 19.88">
      <path
        fill="#2A77F2"
        d="M12.001 2.002c-5.522 0-9.999 4.477-9.999 9.999 0 4.99 3.656 9.126 8.437 9.879v-6.988h-2.54v-2.891h2.54V9.798c0-2.508 1.493-3.891 3.776-3.891 1.094 0 2.24.195 2.24.195v2.459h-1.264c-1.24 0-1.628.772-1.628 1.563v1.875h2.771l-.443 2.891h-2.328v6.988C18.344 21.129 22 16.992 22 12.001c0-5.522-4.477-9.999-9.999-9.999z"></path>
    </svg>,
  ],
  [
    'fb_profile',
    <svg width="85%" viewBox="2 2 20 19.88">
      <path
        fill="#2A77F2"
        d="M12.001 2.002c-5.522 0-9.999 4.477-9.999 9.999 0 4.99 3.656 9.126 8.437 9.879v-6.988h-2.54v-2.891h2.54V9.798c0-2.508 1.493-3.891 3.776-3.891 1.094 0 2.24.195 2.24.195v2.459h-1.264c-1.24 0-1.628.772-1.628 1.563v1.875h2.771l-.443 2.891h-2.328v6.988C18.344 21.129 22 16.992 22 12.001c0-5.522-4.477-9.999-9.999-9.999z"></path>
    </svg>,
  ],
  [
    'twitch',
    <svg width="85%" viewBox="3 3 18.01 18.85">
      <path
        fill="#9247FF"
        d="M4.265 3 3 6.236v13.223h4.502V21l2.531.85 2.392-2.391h3.658l4.923-4.924V3H4.265zm15.052 10.691-2.813 2.814h-4.502l-2.391 2.391v-2.391H5.813V4.688h13.504v9.003zm-2.812-5.767v4.923h-1.688V7.924h1.688zm-4.502 0v4.923h-1.688V7.924h1.688z"></path>
    </svg>,
  ],
  [
    'twitter',
    <svg width="85%" viewBox="2 4.02 19.65 15.96">
      <path
        fill="#36A1F2"
        d="M19.633 7.997c.013.175.013.349.013.523 0 5.325-4.053 11.461-11.46 11.461-2.282 0-4.402-.661-6.186-1.809.324.037.636.05.973.05a8.07 8.07 0 0 0 5.001-1.721 4.036 4.036 0 0 1-3.767-2.793c.249.037.499.062.761.062.361 0 .724-.05 1.061-.137a4.027 4.027 0 0 1-3.23-3.953v-.05c.537.299 1.16.486 1.82.511a4.022 4.022 0 0 1-1.796-3.354c0-.748.199-1.434.548-2.032a11.457 11.457 0 0 0 8.306 4.215c-.062-.3-.1-.611-.1-.923a4.026 4.026 0 0 1 4.028-4.028c1.16 0 2.207.486 2.943 1.272a7.957 7.957 0 0 0 2.556-.973 4.02 4.02 0 0 1-1.771 2.22 8.073 8.073 0 0 0 2.319-.624 8.645 8.645 0 0 1-2.019 2.083z"></path>
    </svg>,
  ],
  [
    'custom',
    <svg width="85%" class="rotate--180" viewBox="2 3 20 18">
      <path
        fill="#333333"
        d="M20 3H4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2zm-5 5h-2V6h2zm4 0h-2V6h2zm1 5H4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2zm-5 5h-2v-2h2zm4 0h-2v-2h2z"></path>
    </svg>,
  ],
]);
export const timeHours = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
];
export const timeMins = [
  '00',
  '05',
  '10',
  '15',
  '20',
  '25',
  '30',
  '35',
  '40',
  '45',
  '50',
  '55',
];
export const timeMeridiem = ['AM', 'PM'];

export const eventsAndRecordingTypes = [
  {label: 'All Events & Recordings', value: ''},
  {label: 'Future Events', value: 'future'},
  {label: 'Past Events', value: 'past'},
  {label: 'Recordings', value: 'recording'},
  {label: 'Replay-Enabled', value: 'replay'},
];

export const isMobileBrowser = () => {
  const userAgent = window.navigator.userAgent;
  const mobileRegex =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  return mobileRegex.test(userAgent);
};

export const PasswordRegex = /^[a-zA-Z0-9!@#$%^&*()\p{L}-]*$/u;
export const PasswordErrorMsg =
  'Password may contain upper-case letters, lower-case letters, numbers & the following symbols !@#$%^&*()';
export const DEFAULT_LAODING_MSG = 'Please wait...';
export const signupPlans = ['free', 'pro', 'ultimate'];

export const test_mode_id = 'LbvbmWbOrdKQPlHPoaTeYck';
export const UnableToProcessMsg =
  'Something went wrong, unable to connect to meetn, please check your network.';

export const AVATAR_COLORS = [
  '232, 105, 156',
  '255, 198, 115',
  '128, 128, 255',
  '105, 232, 194',
  '234, 255, 128',
];
export const NUMBERS_LIST = {
  '+1 540-202-2235': 'USA (Nationwide)',
  '+1 647-948-3247': 'Canada (Toronto)',
  '+1 438-239-9633': 'Canada (Montreal)',
  '+1 778-300-3668': 'Canada (Vancouver)',
  '+61 7 3910 4987': 'Australia (Brisbane)',
  '+61 3 8373 8604': 'Australia (Melbourne)',
  '+61 8 6323 8433': 'Australia (Perth)',
  '+61 2 8379 8045': 'Australia (Sydney)',
  '+45 78 79 47 33': 'Denmark (Copenhagen)',
  '+97 2 3-3752019': 'Israel (Tel Aviv)',
  '+52 55 7100 0505': 'Mexico (Mexico City)',
  '+31 20 262 87 65': 'Netherlands (Amsterdam)',
  '+31 70 262 00 80': 'Netherlands (The Hague)',
  '+31 10 200 59 87': 'Netherlands (Rotterdam)',
  '+64 9 971 0678': 'New Zealand (Auckland)',
  '+64 7 808 6246': 'New Zealand (Hamilton)',
  '+64 3 669 5205': 'New Zealand (Queenstown)',
  '+64 4 886 2700': 'New Zealand (Wellington)',
  '+35 1 210 001 523': 'Portugal (Lissabon)',
  '+34 910 781 837': 'Spain (Madrid)',
  '+34 931 222 830': 'Spain (Barcelona)',
  '+46 3-130 456 96': 'Sweden (Gothenburg)',
  '+46 8-525 038 20': 'Sweden (Stockholm)',
  '+44 20 3151 1327': 'U.K. (London)',
};
export const AVATAR_OPACITY = 0.4;

export const MicCameraDisabledText =
  'Your mic & camera is disabled because the meeting is in “Large Event Mode”';
export const PasswordTooltipMsg =
  'Minimum 4 Characters. May contain Upper-case Letters, Lower-case Letters, Numbers, and the following symbols ! @ # $ % ^ & * ( )';
export const LEM_ERROR_MSG = 'The Host is Not Streaming';
export const LEM_STREAM_ERROR_MSG =
  'Cannot connect to stream.  Is there a network issue?  Is a firewall or VPN blocking live video?';
export const END_MEETN_MSG = 'The host has ended the meeting.';
export const KICKED_OUT_MSG = 'You have been removed from the meeting.';
export const MEETN_END_MSG = 'This meeting has ended.';
export const CAMERA_FAILED_MSG =
  'We are unable to access your camera. Please check if another application is using this device, select another device from the Audio/Video settings menu or try to reload the application.';
export const MicDisabledTextForGuest =
  'In this room, only a host can unmute you.';
export const INVALID_URL_MSG =
  'Something went wrong. Please check the url and try again.';
export const MEDIA_URL = 'https://download.meetn.com/profile/';
export const ROOM_NAME_UPDATE_MSG =
  "You're about to change your room name. This will end meeting for all attendees.";
export const ULTIMATE_FEATURE_MSG =
  'This feature is only available in our Ultimate room. Account owner change the plan of this room to Ultimate.';
export const ULTIMATE_FEATURE_TRIAL_MSG =
  "This feature is only available in our Ultimate room. You're currently on Pro room trial. Account owner can upgrade this account from trial to full and than change the plan of this room to Ultimate.";

export const WAITING_ROOM_DECLINED_MSG =
  'Your request to enter the room was declined.';

export const LARGE_FONT_SIZE = 1.5;
export const MEDIUM_FONT_SIZE = 1.2;
export const SMALL_FONT_SIZE = 0.95;

export const FONT_SIZE_OPTIONS = [
  {label: 'Large Text', value: LARGE_FONT_SIZE},
  {label: 'Medium Text', value: MEDIUM_FONT_SIZE},
  {label: 'Small Text', value: SMALL_FONT_SIZE},
];
