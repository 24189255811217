/* eslint-disable react-native/no-inline-styles */
import React from 'react';

function VideoElementMp4({cb}) {
  cb(true);

  return (
    <>
      <video id="leftVideo" playsInline controls loop>
        <source id="vidSource" src="chrome.mp4" type="video/mp4" />
        <p>This browser does not support the video element.</p>
      </video>
    </>
  );
}

export default VideoElementMp4;
