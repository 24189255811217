import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Audio } from '../../../common/web';
import { getTrackKey } from '../../../../utils/functions';

export default function AudioContainer() {
  const [audioTracks, setAudioTracks] = useState([]);

  const participantsJID = useSelector(state => state.conf.participantsJID);

  const participantsTracks = useSelector(
    state => state.conf.participantsTracks,
  );
  const speaker = useSelector(state => state.conf.speaker);
  const mediaShareparticipantsJID = useSelector(
    state => state.conf.mediaShareparticipantsJID,
  );

  useEffect(() => {
    if (speaker) {
      const allAudioTracks = [];
      Object.keys(participantsJID).forEach(key => {
        let trackKey = getTrackKey(key, 'audio');
        let participantTrack = participantsTracks[trackKey];
        if (participantTrack) {
          allAudioTracks.push(participantTrack);
        }
      });
      let mediaTrackKey = getTrackKey(mediaShareparticipantsJID, 'audio');
      let mediaParticipantTrack = participantsTracks[mediaTrackKey];

      if (mediaParticipantTrack) {
        allAudioTracks.push(mediaParticipantTrack);
      }
      setAudioTracks(allAudioTracks);
    } else {
      setAudioTracks([]);
    }
  }, [participantsTracks, participantsJID, speaker, mediaShareparticipantsJID]);

  return (
    <div>
      {' '}
      {audioTracks.map(track => {
        const localAudioTrack = track.jitsiTrack;
        let audioTrack = { jitsiTrack: localAudioTrack };
        return (
          <Audio
            key={`audio_${localAudioTrack.getId()}`}
            audioTrack={audioTrack}
            _volume={track.volume}
          />
        );
      })}
    </div>
  );
}
