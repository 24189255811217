import {io} from 'socket.io-client';
import {socketURL} from './constants';
import {store} from '../reduxStore/store';
import {
  setChatMessage,
  setFeatureMsg,
  setRoomDetails,
  setSocketConnected,
  setParticipantsList,
  updParticipantsList,
  remParticipantsList,
  setUnreadMessageCount,
  setEventsList,
  setChatBlockUser,
  setMuteRequest,
  setUnmuteRequest,
  setExtensionDetails,
  setExtensionShow,
  setStopScreenShareRequest,
  setStopVideoRequest,
  setKickedOutRequest,
  setLemAttendeesCount,
  setVideoContainerSettings,
} from '../reduxStore/confSlice';
import {
  IndividualUserActionPerform,
  askToUnmuteHandler,
  btnAccess,
  keepMutedHandler,
  kickOut,
} from './functions';

let socket = null;
const {dispatch} = store;

export const socketConnect = (
  room,
  token,
  presenter = '',
  host = '',
  meeting_id = '',
) => {
  return new Promise(resolve => {
    socket = io.connect(socketURL, {
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: 99999,
    });

    socket.on('connect', () => {
      if (socket.connected) {
        socket.emit(
          'con',
          {
            room,
            token,
            p: presenter,
            h: host,
            id: meeting_id,
          },
          response => {
            dispatch(setSocketConnected(true));
            return resolve(response);
          },
        );
      }
    });

    ///

    socket.io.on('ping', () => {
      console.log('socket.ping');
    });

    socket.on('disconnect', () => {
      console.log('socket.disconnect');
    });

    socket.io.on('reconnect', attempt => {
      console.log('socket.reconnect', attempt);
    });

    socket.io.on('reconnect_attempt', attempt => {
      console.log('socket.reconnect_attempt', attempt);
    });

    socket.io.on('reconnect_error', error => {
      console.log('socket.reconnect_error', error);
      return resolve({status: 500, data: 'socket.reconnect_error'});
    });

    socket.io.on('reconnect_failed', () => {
      console.log('socket.reconnect_failed');
      return resolve({status: 500, data: 'socket.reconnect_failed'});
    });

    ///
    socket.on('all', doc => {
      console.log('ALL', doc);
      dispatch(setParticipantsList(doc));
    });

    socket.on('upd', doc => {
      console.log('UPD', doc);
      dispatch(updParticipantsList(doc));
    });

    socket.on('add', doc => {
      dispatch(updParticipantsList(doc));
    });

    socket.on('rem', doc => {
      dispatch(remParticipantsList(doc));
    });

    socket.on('msg', doc => {
      console.log('sendChatMessage msg on', doc);
      const state = store.getState();
      const {chatMessage, chatClick, unreadMessageCount} = state.conf;
      if (!chatClick && btnAccess('chat')) {
        dispatch(setUnreadMessageCount(unreadMessageCount + 1));
      }
      const updatedChatMessage = [...chatMessage];
      updatedChatMessage.push(doc);
      dispatch(setChatMessage(updatedChatMessage));
    });

    socket.on('msg-delete', doc => {
      const state = store.getState();
      const chatMessage = [...state.conf.chatMessage];
      const updatedChatMessage = chatMessage.filter(msg => msg.id !== doc.id);
      dispatch(setChatMessage(updatedChatMessage));
    });

    socket.on('msg-featured', doc => {
      // console.log('msg-featured on ', doc);
      dispatch(setFeatureMsg(doc));
    });

    socket.on('msg-unfeatured', () => {
      // console.log('msg-unfeatured on');
      dispatch(setFeatureMsg({}));
    });

    socket.on('chat-block-list', doc => {
      // console.log('chat-block-list on', doc);
      dispatch(setChatBlockUser(doc));
    });

    socket.on('individual-attendee-action', doc => {
      console.log('individual-attendee-action', doc);
      IndividualUserActionPerform(doc?.action);
    });

    socket.on('chat-block', doc => {
      const state = store.getState();
      const tempchatBlockUsers = [...state.conf.chatBlockUsers];
      tempchatBlockUsers.push(doc.token_unique);
      dispatch(setChatBlockUser(tempchatBlockUsers));
    });

    socket.on('chat-unblock', doc => {
      const state = store.getState();
      const tempchatBlockUsers = [...state.conf.chatBlockUsers];
      const temIndex = tempchatBlockUsers.findIndex(
        item => item === doc.token_unique,
      );
      tempchatBlockUsers.splice(temIndex, 1);
      dispatch(setChatBlockUser(tempchatBlockUsers));
    });

    socket.on('room-settings', doc => {
      const state = store.getState();
      const {roomDetail, localUser, videoContainerSettings} = state.conf;
      if (
        videoContainerSettings?.show_speaker_on_presentation !==
          doc?.show_speaker_on_presentation ||
        videoContainerSettings?.show_names_on_videos !==
          doc?.show_names_on_videos ||
        videoContainerSettings?.hide_muted_attendees !==
          doc?.hide_muted_attendees
      ) {
        const show_speaker_on_presentation =
          doc?.show_speaker_on_presentation ??
          videoContainerSettings?.show_speaker_on_presentation;
        const show_names_on_videos =
          doc?.show_names_on_videos ??
          videoContainerSettings?.show_names_on_videos;
        const hide_muted_attendees =
          doc?.hide_muted_attendees ??
          videoContainerSettings?.hide_muted_attendees;
        dispatch(
          setVideoContainerSettings({
            show_speaker_on_presentation,
            show_names_on_videos,
            hide_muted_attendees,
          }),
        );
      }

      if (doc?.mute_all && localUser?.role === 'guest' && localUser?.audio) {
        IndividualUserActionPerform('mute');
      } else if (
        doc?.unmute_all &&
        localUser?.role === 'guest' &&
        !localUser?.audio
      ) {
        IndividualUserActionPerform('unmute');
      }
      const tempRoomDetail = {...roomDetail, ...doc};
      console.log('on room-settings', doc);
      dispatch(setRoomDetails(tempRoomDetail));
    });
    socket.on('lem-attendees-count', doc => {
      dispatch(setLemAttendeesCount(doc.count));
    });
    //extensions
    socket.on('show', doc => {
      console.log('extensions - show', doc);
      dispatch(setExtensionShow(true));
      dispatch(setExtensionDetails(doc));
    });

    socket.on('hide', doc => {
      console.log('extensions - hide', doc);
      dispatch(setExtensionShow(false));
      dispatch(setExtensionDetails({}));
    });
  });
};

export const socketDisconnect = () => {
  socket.disconnect();
};
///

export const hostLogin = doc => {
  return new Promise(resolve => {
    socket.emit('login', doc, res => {
      return resolve(res);
    });
  });
};

export const addParticipant = doc => {
  return new Promise(resolve => {
    socket.emit('add', doc, res => {
      return resolve(res);
    });
  });
};

export const updParticipant = doc => {
  console.log('updParticipant', doc);
  return new Promise(resolve => {
    socket.emit('upd', doc, res => {
      return resolve(res);
    });
  });
};

export const remParticipant = doc => {
  return new Promise(resolve => {
    socket.emit('rem', doc, res => {
      return resolve(res);
    });
  });
};

// export const waitingAccept = doc => {
//   return new Promise(resolve => {
//     socket.emit('w-accept', doc, res => {
//       return resolve(res);
//     });
//   });
// };

// export const waitingDecline = doc => {
//   return new Promise(resolve => {
//     socket.emit('w-decline', doc, res => {
//       return resolve(res);
//     });
//   });
// };

// export const waitingAcceptAll = doc => {
//   console.log('waitingAcceptAll doc', doc);
//   return new Promise(resolve => {
//     socket.emit('w-accept-all', doc, res => {
//       console.log('waitingAcceptAll res', res);
//       return resolve(res);
//     });
//   });
// };

// export const waitingDeclineAll = doc => {
//   console.log('waitingDeclineAll doc', doc);
//   return new Promise(resolve => {
//     socket.emit('w-decline-all', doc, res => {
//       console.log('waitingDeclineAll res', res);
//       return resolve(res);
//     });
//   });
// };

// export const muteAll = doc => {
//   console.log('muteAll doc', doc);
//   return new Promise(resolve => {
//     socket.emit('mute-all', doc, res => {
//       console.log('muteAll res', doc);
//       return resolve(res);
//     });
//   });
// };

// export const unmuteAll = doc => {
//   console.log('unmuteAll doc', doc);
//   return new Promise(resolve => {
//     socket.emit('unmute-all', doc, res => {
//       console.log('unmuteAll res', res);
//       return resolve(res);
//     });
//   });
// };

export const toggleWaitingRoom = () => {
  return new Promise(resolve => {
    socket.emit('w-decline-all', res => {
      return resolve(res);
    });
  });
};

export const roomSettings = doc => {
  console.log('room-settings doc', doc);
  return new Promise(resolve => {
    socket.emit('room-settings', doc, res => {
      console.log('room-settings res', res);
      return resolve(res);
    });
  });
};

export const startRecording = doc => {
  return new Promise(resolve => {
    socket.emit('start-recording', doc, res => {
      return resolve(res);
    });
  });
};

export const stopRecording = doc => {
  return new Promise(resolve => {
    socket.emit('stop-recording', doc, res => {
      return resolve(res);
    });
  });
};

///

export const sendChatMessage = doc => {
  console.log('sendChatMessage doc', doc);
  return new Promise(resolve => {
    socket.emit('msg', doc, res => {
      console.log('sendChatMessage msg res', res);
      return resolve(res);
    });
  });
};

export const deleteChatMessage = doc => {
  console.log('deleteChatMessage doc', doc);
  return new Promise(resolve => {
    socket.emit('msg-delete', doc, res => {
      console.log('deleteChatMessage res', res);
      return resolve(res);
    });
  });
};

export const featureChatMessage = doc => {
  console.log('msg-featured emit', doc);
  return new Promise(resolve => {
    socket.emit('msg-featured', doc, res => {
      return resolve(res);
    });
  });
};

export const unfeatureChatMessage = doc => {
  console.log('msg-unfeatured emit', doc);
  return new Promise(resolve => {
    socket.emit('msg-unfeatured', doc, res => {
      return resolve(res);
    });
  });
};

export const disposeMeetnSocketConnection = () => {
  if (socket) {
    socket.disconnect();
  }
};

export const updateProfile = doc => {
  console.log('updateProfile doc', doc);
  return new Promise(resolve => {
    socket.emit('profile', doc, res => {
      console.log('updateProfile res', res);
      return resolve(res);
    });
  });
};

export const getSignedUrl = doc => {
  console.log('getSignedUrl doc', doc);
  return new Promise(resolve => {
    socket.emit('upload', doc, res => {
      console.log('getSignedUrl res', res);
      return resolve(res);
    });
  });
};

export const addRoomPermission = doc => {
  console.log('addRoomPermission doc', doc);
  return new Promise(resolve => {
    socket.emit('room-permission-add', doc, res => {
      console.log('addRoomPermission res', res);
      return resolve(res);
    });
  });
};

export const removeRoomPermission = doc => {
  return new Promise(resolve => {
    socket.emit('room-permission-remove', doc, res => {
      return resolve(res);
    });
  });
};

export const getRoomPermissionList = doc => {
  console.log('room-permissions doc', doc);
  return new Promise(resolve => {
    socket.emit('room-permissions', doc, res => {
      return resolve(res);
    });
  });
};

export const eventsAndRecording = doc => {
  console.log('eventsAndRecording doc', doc);
  return new Promise(resolve => {
    socket.emit('events-recordings-upd', doc, res => {
      console.log('eventsAndRecording res', res);
      return resolve(res);
    });
  });
};

export const getEventsAndRecordingList = doc => {
  console.log('events-recordings-list doc', doc);
  return new Promise(resolve => {
    socket.emit('events-recordings-list', doc, res => {
      console.log('events-recordings-list res', res);
      return resolve(res);
    });
  });
};

export const removeEventsAndRecording = doc => {
  return new Promise(resolve => {
    socket.emit('events-recordings-del', doc, res => {
      return resolve(res);
    });
  });
};

export const getVirtualBackgroundList = doc => {
  return new Promise(resolve => {
    socket.emit('virtual-background-list', doc, res => {
      return resolve(res);
    });
  });
};

export const uploadVirtualBackground = doc => {
  return new Promise(resolve => {
    socket.emit('virtual-background-upload', doc, res => {
      return resolve(res);
    });
  });
};

export const deleteVirtualBackground = doc => {
  return new Promise(resolve => {
    socket.emit('virtual-background-delete', doc, res => {
      return resolve(res);
    });
  });
};

export const blockUsers = doc => {
  return new Promise(resolve => {
    socket.emit('chat-block', doc, res => {
      return resolve(res);
    });
  });
};

export const unblockUsers = doc => {
  return new Promise(resolve => {
    socket.emit('chat-unblock', doc, res => {
      return resolve(res);
    });
  });
};

export const getStreamingDestinations = doc => {
  return new Promise(resolve => {
    socket.emit('streaming-destination-list', doc, res => {
      return resolve(res);
    });
  });
};

export const getfbPagesList = doc => {
  return new Promise(resolve => {
    socket.emit('streaming-destination-facebook-page-list', doc, res => {
      return resolve(res);
    });
  });
};
export const addFacebookDestination = doc => {
  return new Promise(resolve => {
    socket.emit('stream-destination-facebook-add', doc, res => {
      return resolve(res);
    });
  });
};
export const deleteStreamingDestinations = doc => {
  return new Promise(resolve => {
    socket.emit('streaming-destination-delete', doc, res => {
      return resolve(res);
    });
  });
};
export const getfbProfileData = doc => {
  return new Promise(resolve => {
    socket.emit('streaming-destination-facebook-profile', doc, res => {
      return resolve(res);
    });
  });
};

export const getfbGroupsList = doc => {
  return new Promise(resolve => {
    socket.emit('streaming-destination-facebook-group-list', doc, res => {
      return resolve(res);
    });
  });
};

export const validateFbAppInstalled = doc => {
  return new Promise(resolve => {
    socket.emit(
      'streaming-destination-facebook-group-app-installed',
      doc,
      res => {
        return resolve(res);
      },
    );
  });
};

export const addRoomProcess = doc => {
  console.log('add-room-process doc', doc);
  return new Promise(resolve => {
    socket.emit('add-room-process', doc, res => {
      console.log('add-room-process res', res);
      return resolve(res);
    });
  });
};

export const addRoomConfirm = doc => {
  console.log('add-room-confirm doc', doc);
  return new Promise(resolve => {
    socket.emit('add-room-confirm', doc, res => {
      console.log('add-room-confirm res', res);
      return resolve(res);
    });
  });
};

export const getRoomList = doc => {
  console.log('room-list doc', doc);
  return new Promise(resolve => {
    socket.emit('room-list', doc, res => {
      console.log('room-list res', res);
      return resolve(res);
    });
  });
};

export const updateRoomPlanConfirm = doc => {
  console.log('change-plan-confirm doc', doc);
  return new Promise(resolve => {
    socket.emit('change-plan-confirm', doc, res => {
      console.log('change-plan-confirm res', res);
      return resolve(res);
    });
  });
};

export const updateRoomPlanProcess = doc => {
  console.log('change-plan-process doc', doc);
  return new Promise(resolve => {
    socket.emit('change-plan-process', doc, res => {
      console.log('change-plan-process res', res);
      return resolve(res);
    });
  });
};

export const deleteRoomConfirm = doc => {
  console.log('delete-room-confirm doc', doc);
  return new Promise(resolve => {
    socket.emit('delete-room-confirm', doc, res => {
      console.log('delete-room-confirm res', res);
      return resolve(res);
    });
  });
};

export const deleteRoomProcess = doc => {
  console.log('delete-room-process doc', doc);
  return new Promise(resolve => {
    socket.emit('delete-room-process', doc, res => {
      console.log('delete-room-process res', res);
      return resolve(res);
    });
  });
};

export const revokeDeleteRoomPermission = doc => {
  console.log('delete-room-revoke doc', doc);
  return new Promise(resolve => {
    socket.emit('delete-room-revoke', doc, res => {
      console.log('delete-room-revoke res', res);
      return resolve(res);
    });
  });
};

export const revokeDowngradeRoomPermission = doc => {
  console.log('downgrade-room-revoke doc', doc);
  return new Promise(resolve => {
    socket.emit('downgrade-room-revoke', doc, res => {
      console.log('downgrade-room-revoke res', res);
      return resolve(res);
    });
  });
};

export const lemAuthenticate = doc => {
  return new Promise(resolve => {
    socket.emit('lem', doc, res => {
      return resolve(res);
    });
  });
};

export const getDownloadedSignedUrl = doc => {
  console.log('getDownloadedSignedUrl doc', doc);
  return new Promise(resolve => {
    socket.emit('download', doc, res => {
      console.log('getDownloadedSignedUrl res', res);
      return resolve(res);
    });
  });
};

export const startSocialStreaming = doc => {
  return new Promise(resolve => {
    socket.emit('start-streaming', doc, res => {
      return resolve(res);
    });
  });
};

export const endMeetn = doc => {
  return new Promise(resolve => {
    socket.emit('end-meetn-guests', doc, res => {
      return resolve(res);
    });
  });
};

export const upgradeAccountConfirm = doc => {
  console.log('upgrade-account-confirm doc', doc);
  return new Promise(resolve => {
    socket.emit('upgrade-account-confirm', doc, res => {
      console.log('upgrade-account-confirm res', res);
      return resolve(res);
    });
  });
};

export const upgradeAccountProcess = doc => {
  console.log('upgrade-account-process doc', doc);
  return new Promise(resolve => {
    socket.emit('upgrade-account-process', doc, res => {
      console.log('upgrade-account-process res', res);
      return resolve(res);
    });
  });
};

export const removeRoomInvite = doc => {
  return new Promise(resolve => {
    socket.emit('room-invite-remove', doc, res => {
      return resolve(res);
    });
  });
};

export const validateRoomPasscode = doc => {
  return new Promise(resolve => {
    socket.emit('passcode', doc, res => {
      return resolve(res);
    });
  });
};

export const stopSocialStreaming = doc => {
  return new Promise(resolve => {
    socket.emit('stop-streaming', doc, res => {
      return resolve(res);
    });
  });
};

export const guestPinUpdate = doc => {
  console.log('guest-pin-upd doc', doc);
  return new Promise(resolve => {
    socket.emit('guest-pin-upd', doc, res => {
      console.log('guest-pin-upd res', res);
      return resolve(res);
    });
  });
};

export const hostPinUpdate = doc => {
  console.log('host-pin-upd doc', doc);
  return new Promise(resolve => {
    socket.emit('host-pin-upd', doc, res => {
      console.log('host-pin-upd res', res);
      return resolve(res);
    });
  });
};

export const presenterLinkUpdate = doc => {
  console.log('presenter-link-upd doc', doc);
  return new Promise(resolve => {
    socket.emit('presenter-link-upd', doc, res => {
      console.log('presenter-link-upd res', res);
      return resolve(res);
    });
  });
};

export const hostLinkUpdate = doc => {
  console.log('host-link-upd  doc', doc);
  return new Promise(resolve => {
    socket.emit('host-link-upd', doc, res => {
      console.log('host-link-upd  res', res);
      return resolve(res);
    });
  });
};

export const updateRoomName = doc => {
  console.log('room-name-upd  doc', doc);
  return new Promise(resolve => {
    socket.emit('room-name-upd', doc, res => {
      console.log('room-name-upd  res', res);
      return resolve(res);
    });
  });
};

export const getExtensionList = doc => {
  console.log('exten-list doc', doc);
  return new Promise(resolve => {
    socket.emit('exten-list', doc, res => {
      console.log('exten-list res', res);
      return resolve(res);
    });
  });
};

export const reportProblem = doc => {
  return new Promise(resolve => {
    socket.emit('report-problem', doc, res => {
      return resolve(res);
    });
  });
};
export const individualAttendeeAction = doc => {
  return new Promise(resolve => {
    socket.emit('individual-attendee-action', doc, res => {
      return resolve(res);
    });
  });
};

export const getPasscode = doc => {
  console.log('getPasscode doc', doc);
  return new Promise(resolve => {
    socket.emit('passcode-get', doc, res => {
      console.log('getPasscode res', res);
      return resolve(res);
    });
  });
};

export const newEventTimeValidate = doc => {
  console.log('new-event-time-validate doc', doc);
  return new Promise(resolve => {
    socket.emit('new-event-time-validate', doc, res => {
      console.log('new-event-time-validate res', res);
      return resolve(res);
    });
  });
};

export const multipleAttendeeAction = doc => {
  return new Promise(resolve => {
    socket.emit('multiple-attendee-action', doc, res => {
      return resolve(res);
    });
  });
};
export const getLemSignedUrl = doc => {
  return new Promise(resolve => {
    socket.emit('lem-signed-url', doc, res => {
      return resolve(res);
    });
  });
};

export const updateInactiveVideoLog = doc => {
  return new Promise(resolve => {
    socket.emit('video-triangle-log', doc, res => {
      return resolve(res);
    });
  });
};
