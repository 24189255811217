/* eslint-disable react-native/no-inline-styles */
import React, {useEffect, useMemo, useState} from 'react';
import VideoElement from './videoElement';
import {AttendeeName, Avatar} from '../../../common/web';
import {useSelector} from 'react-redux';
import {
  getParticipantAudioStatus,
  getTrackVideoStatus,
} from '../../../../utils/functions';
import VideoElementMp4 from './VideoElementMp4';

function VideoBlockContainer({participant, isVideoInactive, setMp4Video}) {
  const {mirrorSelfView} = useSelector(state => state.persist);
  const localUser = useSelector(state => state.conf.localUser);
  const audioMode = useSelector(state => state.conf.audioMode);
  const trackStreamStatus = useSelector(state => state.conf.trackStreamStatus);
  const unmutedParticipantIds = useSelector(
    state => state.conf.unmutedParticipantIds,
  );
  const isLocal = participant.jid === localUser.jid;
  let mirrorClass = isLocal && mirrorSelfView ? 'flipVideoX' : '';
  const {track} = participant;
  const [showWarning, setWarning] = useState(false);
  // const sourceName = track?.getSourceName();
  // useEffect(() => {
  //     // console.log('track=', track, '==', sourceName);
  //     if (track && track?.videoType === 'camera' && !isLocal) {
  //         track.on(JitsiTrackEvents.TRACK_STREAMING_STATUS_CHANGED, handleTrackStreamingStatusChanged);
  //         // handleTrackStreamingStatusChanged(track, track?.getTrackStreamingStatus());
  //         // console.log('track= on', track?.getTrackStreamingStatus(), sourceName);
  //     }

  //     return () => {
  //         if (track && track?.videoType === 'camera' && !isLocal) {
  //             track.off(JitsiTrackEvents.TRACK_STREAMING_STATUS_CHANGED, handleTrackStreamingStatusChanged);
  //             // handleTrackStreamingStatusChanged(track, track?.getTrackStreamingStatus());
  //             // console.log('track= off', track?.getTrackStreamingStatus(), sourceName);
  //         }
  //     };
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [sourceName]);

  useEffect(() => {
    let debounceTimer = 0;
    if (isVideoInactive) {
      debounceTimer = setTimeout(() => {
        if (isVideoInactive) {
          setWarning(true);
        }
      }, 3000);
    }

    return () => {
      clearTimeout(debounceTimer);
      setWarning(false);
    };
  }, [isVideoInactive]);

  const _streamStatus = useMemo(() => {
    return getTrackVideoStatus(track);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [track, trackStreamStatus]);

  const audioMuted = useMemo(() => {
    let currentStatus = isLocal
      ? localUser?.audio
      : getParticipantAudioStatus(participant);
    return !currentStatus;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participant, unmutedParticipantIds]);

  return (
    <div
      className="videoBlockInner"
      key={participant.jid + '_' + participant.type + '_child1'}>
      {participant.local ? (
        <>
          <VideoElementMp4 cb={setMp4Video} />
          <AttendeeName
            name={participant.name}
            key={`${participant.jid}_${participant.type}_child2`}
          />
        </>
      ) : participant.track ? (
        <>
          <VideoElement track={track} className={mirrorClass} />
          {/* When video is visible */}

          <AttendeeName
            name={participant.name}
            userkey={`${participant.jid}_${participant.type}_child2`}
          />

          {/* When video is off */}
        </>
      ) : (
        <>
          <Avatar
            participant={participant}
            userkey={participant.jid + '_' + participant.type + '_child2'}
            showWarning={showWarning}
            streamStatus={_streamStatus}
          />
        </>
      )}
      {audioMuted && (
        <span className=" mt-2 mr-2 position-absolute top-0 right-0">
          {/* <svg width="1.5em" height="1.5em" viewBox="0 0 24 24" fill="#fe0000">
                        <path d="m21.707 20.293-3.388-3.388A7.942 7.942 0 0 0 20 12.021h-2a5.95 5.95 0 0 1-1.109 3.456l-1.452-1.452c.348-.591.561-1.27.561-2.004v-6C16 3.804 14.215 2 12.021 2c-.07 0-.14.009-.209.025A4.005 4.005 0 0 0 8 6.021v.565L3.707 2.293 2.293 3.707l18 18 1.414-1.414zM10 6.021c0-1.103.897-2 2-2a.918.918 0 0 0 .164-.015C13.188 4.08 14 4.956 14 6.021v6c0 .172-.029.335-.071.494L10 8.586V6.021zm-4 6H4c0 4.072 3.06 7.436 7 7.931v2.069h2v-2.07a7.993 7.993 0 0 0 2.218-.611l-1.558-1.558a5.979 5.979 0 0 1-1.66.239c-3.309 0-6-2.692-6-6z" /><path d="M8.011 12.132a3.993 3.993 0 0 0 3.877 3.877l-3.877-3.877z" />
                    </svg> */}
          <img
            src={'assets/images/monochrome/mic-off-red.png'}
            alt=""
            className="theme-monochrome-img"
            style={{width: '1.2em', height: '1.2em'}}
          />
        </span>
      )}
    </div>
  );
}

export default VideoBlockContainer;
