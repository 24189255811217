import {toast} from 'react-toastify';
import {
  setActiveParticipant,
  setBilling,
  setCurrentActiveVideoTracks,
  setForceUpdate,
  setGalleryViewParticipants,
  setMuteUnmutePermission,
  setParticipantsJIDList,
  setPresentationParticipant,
  setTrial,
  setUserDetail,
} from '../../../../reduxStore/confSlice';
import {store} from '../../../../reduxStore/store';
import {
  getPasscode,
  updParticipant,
  updateProfile,
  upgradeAccountConfirm,
  upgradeAccountProcess,
} from '../../../../utils/socket';
import {
  calculateGallerySize,
  getParticipiantsJidSortedData,
  getTrackKey,
  updateRecenetUsersList,
  updateUserSettingsData,
} from '../../../../utils/functions';
import {
  changeTrackResolution,
  changeTrackResolutionActiveSpeaker,
  changeTrackResolutionPresentation,
  changeTrackResolutionSmallActive,
  localAudioTrackUnMute,
} from '../../../../libs';
import {setMirrorSelfView} from '../../../../reduxStore/persistSlice';
import {test_mode_id} from '../../../../utils/util';
import {alertMsg} from '../../../common/web/alert';
import swal from 'sweetalert';

export const updateUserSettings = key => {
  updateUserSettingsData(key, msg => {
    toast.error(msg, {
      position: toast.POSITION.TOP_CENTER,
    });
  });
};

export const setMirrorView = e => {
  const {dispatch} = store;
  if (e.target.checked) {
    dispatch(setMirrorSelfView(true));
  } else {
    dispatch(setMirrorSelfView(false));
  }
};

export const upgradeAccountConfirmHandler = e => {
  e.preventDefault();
  const state = store.getState();
  const {localUser, roomDetail} = state.conf;
  const {email, token} = localUser;
  const {room} = roomDetail;
  upgradeAccountConfirm({token, email, room: room}).then(res => {
    if (res.status === 200) {
      swal({
        title: res.data[0].msg,
        closeOnClickOutside: false,
        buttons: ['Cancel', 'Confirm'],
        dangerMode: true,
      }).then(Confirm => {
        if (Confirm) {
          upgradeAccountProcessHandler(room, token, email);
        }
      });
    } else {
      toast.error(res.data[0].msg, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  });
};

const upgradeAccountProcessHandler = (room, token, email) => {
  const {dispatch} = store;
  upgradeAccountProcess({
    room: room,
    token,
    test_mode_id: test_mode_id,
    email,
  }).then(res => {
    if (res.status === 200) {
      dispatch(setTrial(res.data[0].trail));
      dispatch(setBilling(res.data[0].billing));
      alertMsg({msg: res.data[0].msg});
    } else {
      toast.error(res.data[0].msg, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  });
};

export const getPasscodeHandler = setState => {
  const state = store.getState();
  const {localUser, roomDetail} = state.conf;
  const {token} = localUser;
  const {room} = roomDetail;
  getPasscode({room, token}).then(res => {
    if (res.status === 200) {
      setState(prevState => ({...prevState, passcode: res?.data[0].passcode}));
    }
  });
};

export const getTrack = track => {
  return track !== null && track?.muted === false ? track : null;
};

export const setGalleryParticipiants = tmpParticipantsJID => {
  const state = store.getState();
  const {dispatch} = store;
  const {
    participantsJID,
    roomDetail,
    numPages,
    maxVideos,
    localDesktopVideoTrack,
    localVideoTrack,
    localUser,
    currrentPage,
    participantsTracks,
    unmutedParticipantIds,
    audioMode,
  } = state.conf;

  const {hide_muted_attendees} = roomDetail;

  const desktopVideoTrack = getTrack(localDesktopVideoTrack);
  const videoTrack = localUser.video ? getTrack(localVideoTrack) : null;

  const participantsJIDData = tmpParticipantsJID
    ? tmpParticipantsJID
    : participantsJID;

  let localSelectedTracks = [];
  let galleryParticipantData = [];
  let unmutedUsersForHideMuted = [];

  if (hide_muted_attendees) {
    if (localUser?.audio) {
      if (videoTrack) {
        const participant = {
          track: videoTrack.jitsiTrack,
          type: 'camera',
          name: localUser.name,
          profile_image: localUser?.profile_image,
          jid: localUser.jid,
          local: true,
        };

        localSelectedTracks.push(participant);
      } else {
        const participant = {
          track: null,
          type: 'camera',
          name: localUser.name,
          profile_image: localUser?.profile_image,
          jid: localUser.jid,
          local: true,
        };

        localSelectedTracks.push(participant);
      }

      if (desktopVideoTrack && desktopVideoTrack !== null) {
        const participant = {
          track: desktopVideoTrack.jitsiTrack,
          type: 'desktop',
          name: localUser.name,
          local: true,
        };

        localSelectedTracks.push(participant);
      }
    }
    unmutedUsersForHideMuted.push(...unmutedParticipantIds);
  } else {
    if (videoTrack) {
      const participant = {
        track: videoTrack.jitsiTrack,
        type: 'camera',
        name: localUser.name,
        profile_image: localUser?.profile_image,
        jid: localUser.jid,
        local: true,
      };
      localSelectedTracks.push(participant);
    } else {
      const participant = {
        track: null,
        type: 'camera',
        name: localUser.name,
        profile_image: localUser?.profile_image,
        jid: localUser.jid,
        local: true,
      };
      localSelectedTracks.push(participant);
    }

    if (desktopVideoTrack && desktopVideoTrack !== null) {
      const participant = {
        track: desktopVideoTrack.jitsiTrack,
        type: 'desktop',
        name: localUser.name + '&#39;s Presentation',
        local: true,
      };
      localSelectedTracks.push(participant);
    }
  }

  const pageCount = [];
  for (let i = 0; i < numPages; i++) {
    if (i === 0) {
      let start = 0;
      let end = maxVideos - localSelectedTracks.length;
      pageCount.push({start: start, end: end});
    } else {
      let start = pageCount[i - 1].end;
      let end = pageCount[i - 1].end + maxVideos - localSelectedTracks.length;
      pageCount.push({start: start, end: end});
    }
  }

  let startCount = pageCount[currrentPage - 1]?.start;
  let endCount = pageCount[currrentPage - 1]?.end;

  const finalJid = hide_muted_attendees
    ? unmutedParticipantIds
    : Object.keys(participantsJIDData);

  let requestIds = [];

  let currentActiveTrack = {};

  finalJid.forEach(id => {
    const videoTrackkey = getTrackKey(id, 'video');
    const desktopTrackkey = getTrackKey(id, 'desktop');
    const userVideoTrack = participantsTracks[videoTrackkey];
    const desktopTrack = participantsTracks[desktopTrackkey];

    if (userVideoTrack?.muted === false) {
      const participant = {
        track: userVideoTrack.jitsiTrack,
        type: 'camera',
        name: participantsJIDData[id].name,
        profile_image: participantsJIDData[id]?.profile_image,
        jid: id,
        local: false,
      };
      galleryParticipantData.push(participant);
      currentActiveTrack[videoTrackkey] = userVideoTrack;
      requestIds.push(userVideoTrack.jitsiTrack.getSourceName());
    } else {
      const participant = {
        track: null,
        type: 'camera',
        name: participantsJIDData[id].name,
        profile_image: participantsJIDData[id]?.profile_image,
        jid: id,
        local: false,
      };
      galleryParticipantData.push(participant);
    }

    if (desktopTrack?.muted === false && participantsJIDData[id]?.screenShare) {
      const participant = {
        track: desktopTrack.jitsiTrack,
        type: 'desktop',
        name: participantsJIDData[id].name + '&#39;s Presentation',
        profile_image: participantsJIDData[id]?.profile_image,
        jid: id,
        local: false,
      };
      galleryParticipantData.push(participant);
      requestIds.push(desktopTrack.jitsiTrack.getSourceName());
      currentActiveTrack[desktopTrackkey] = desktopTrack;
    }
  });

  // const finalRequestIds = requestIds.slice(startCount, endCount);
  const specificNumberGalleryParticipantData = galleryParticipantData.slice(
    startCount,
    endCount,
  );

  const finalLocalSelectedTracks =
    currrentPage === 1
      ? localSelectedTracks
      : localSelectedTracks && localSelectedTracks.length > 0
      ? [localSelectedTracks[0]]
      : [];

  const finalGalleryParticipantData = [
    ...finalLocalSelectedTracks,
    ...specificNumberGalleryParticipantData,
  ];

  // const finalCurrentActiveTrack = objSlice(
  //   currentActiveTrack,
  //   startCount,
  //   endCount,
  // );
  let finalCurrentActiveTrack = {};
  let finalRequestIds = [];
  for (let user of specificNumberGalleryParticipantData) {
    for (let key in currentActiveTrack) {
      if (currentActiveTrack[key].participantId === user.jid) {
        finalCurrentActiveTrack[key] = currentActiveTrack[key];
        finalRequestIds.push(
          currentActiveTrack[key]?.jitsiTrack?.getSourceName(),
        );
      }
    }
  }

  if (!audioMode) {
    changeTrackResolution(finalRequestIds);
  }
  dispatch(setCurrentActiveVideoTracks(finalCurrentActiveTrack));
  dispatch(setGalleryViewParticipants(finalGalleryParticipantData));
  dispatch(calculateGallerySize(finalGalleryParticipantData.length));
};

export const ShowActiveSpeakerOnFirstPage = showActiveWindow => {
  const state = store.getState();
  const {dispatch} = store;
  const {dominantSpeaker, participantsJID, localUser, maxVideos} = state.conf;
  if (dominantSpeaker !== null && dominantSpeaker !== localUser?.jid) {
    const galleryViewJid = Object.keys(participantsJID).slice(0, maxVideos - 1);
    const isDominatExist = galleryViewJid.includes(dominantSpeaker);
    if (!isDominatExist) {
      const UpdatedParticipantsJID = {};
      const tmpParticipantsJID = [...Object.keys(participantsJID)];
      const DominatIndex = tmpParticipantsJID.findIndex(
        item => item === dominantSpeaker,
      );
      const sortedList = getParticipiantsJidSortedData(
        Object.values(participantsJID).slice(0, maxVideos - 1),
      );
      const lastIndexFromList = localUser.screenShare
        ? sortedList.length - 2
        : sortedList.length - 1;
      const leastUserSpeakIndex = tmpParticipantsJID.findIndex(
        item => item === sortedList[lastIndexFromList].jid,
      );
      if (DominatIndex !== -1) {
        const tmpData = tmpParticipantsJID[leastUserSpeakIndex];
        tmpParticipantsJID[leastUserSpeakIndex] =
          tmpParticipantsJID[DominatIndex];
        tmpParticipantsJID[DominatIndex] = tmpData;
        tmpParticipantsJID.forEach(jidItem => {
          UpdatedParticipantsJID[jidItem] = participantsJID[jidItem];
        });
        dispatch(setParticipantsJIDList(UpdatedParticipantsJID));
        dispatch(setForceUpdate('gallery'));
        if (!showActiveWindow) {
          setGalleryParticipiants(UpdatedParticipantsJID);
        }
      }
    }
  }
};

export const setGalleryTracks = (
  participantsTracks,
  _isUpdateGallery,
  keepAliveRequest = false,
) => {
  const {dispatch} = store;
  const state = store.getState();
  const {galleryViewParticipants, audioMode} = state.conf;
  let participantId = [];

  let _participants = galleryViewParticipants.map(p => {
    let participant = {...p};
    if (!participant.local) {
      if (participant.type === 'camera') {
        let trackKey = getTrackKey(participant.jid, 'video');
        const remoteVideoTrack = participantsTracks[trackKey];
        if (remoteVideoTrack?.muted === false) {
          participantId.push(remoteVideoTrack.jitsiTrack.getSourceName());
          participant.track = remoteVideoTrack.jitsiTrack;
        } else {
          participant.track = null;
        }
      }
      if (participant.type === 'desktop') {
        let trackKey = getTrackKey(participant.jid, 'desktop');
        const remoteDesktopTrack = participantsTracks[trackKey];
        if (remoteDesktopTrack?.muted === false) {
          participantId.push(remoteDesktopTrack.jitsiTrack.getSourceName());
          participant.track = remoteDesktopTrack.jitsiTrack;
        } else {
          participant.track = null;
        }
      }
    }

    return participant;
  });
  if (!audioMode) {
    changeTrackResolution(participantId, false, keepAliveRequest);
  }

  if (_isUpdateGallery) {
    dispatch(setGalleryViewParticipants(_participants));
  }
};

export const setActiveSmallWindowParticipant = () => {
  const {dispatch} = store;
  const state = store.getState();
  const {
    screenSharedJid,
    roomDetail,
    localUser,
    participantsJID,
    localVideoTrack,
    dominantSpeaker,
    recentActiveSpeaker,
    recentUnmutedUsers,
    currentActiveVideoTracks,
    unmutedParticipantIds,
    participantsTracks,
  } = state.conf;

  const videoTrack = localUser.video ? getTrack(localVideoTrack) : null;
  let track = videoTrack;
  let tmpParticipant = localUser;
  const hideMutedAttendees = roomDetail?.hide_muted_attendees;
  const usersId = [];
  let _currentVideoTracks = {...currentActiveVideoTracks};

  let activeSpeakerParticipant = {};

  unmutedParticipantIds
    .filter(id => id !== dominantSpeaker)
    .forEach(jid => {
      const videoTrackKey = getTrackKey(jid, 'video');
      const userVideoTrack = participantsTracks[videoTrackKey];

      if (userVideoTrack?.muted === false) {
        usersId.push(userVideoTrack.jitsiTrack.getSourceName());
      }
    });

  if (hideMutedAttendees) {
    if (
      (participantsJID[dominantSpeaker] &&
        participantsJID[dominantSpeaker]?.audio === true) ||
      (dominantSpeaker === localUser?.jid && localUser?.audio === true)
    ) {
      if (dominantSpeaker === localUser?.jid) {
        track = videoTrack;
        if (screenSharedJid) {
          const desktopTrackKey = getTrackKey(screenSharedJid, 'desktop');
          const userDesktopTrack = participantsTracks[desktopTrackKey];

          if (userDesktopTrack) {
            usersId.unshift(userDesktopTrack.jitsiTrack.getSourceName());
          }
        }
      } else {
        let id = dominantSpeaker;
        tmpParticipant = participantsJID[dominantSpeaker];
        const videoTrackKey = getTrackKey(id, 'video');
        const userVideoTrack = participantsTracks[videoTrackKey];

        if (userVideoTrack?.muted === false) {
          track = userVideoTrack;
          usersId.unshift(userVideoTrack.jitsiTrack.getSourceName());
        } else {
          track = null;
        }

        if (screenSharedJid) {
          const desktopTrackKey = getTrackKey(screenSharedJid, 'desktop');
          const userDesktopTrack = participantsTracks[desktopTrackKey];

          if (userDesktopTrack) {
            usersId.unshift(userDesktopTrack.jitsiTrack.getSourceName());
          }
        }
      }
    } else if (
      (participantsJID[recentActiveSpeaker[0]] &&
        participantsJID[recentActiveSpeaker[0]].audio === true) ||
      (recentActiveSpeaker[0] === localUser?.jid && localUser?.audio === true)
    ) {
      if (recentActiveSpeaker[0] === localUser?.jid) {
        track = videoTrack;
        if (screenSharedJid) {
          const desktopTrackKey = getTrackKey(screenSharedJid, 'desktop');
          const userDesktopTrack = participantsTracks[desktopTrackKey];

          if (userDesktopTrack) {
            usersId.unshift(userDesktopTrack.jitsiTrack.getSourceName());
          }
        }
      } else {
        let id = recentActiveSpeaker[0];
        tmpParticipant = participantsJID[id];
        const videoTrackKey = getTrackKey(id, 'video');
        const userVideoTrack = participantsTracks[videoTrackKey];

        if (userVideoTrack?.muted === false) {
          track = userVideoTrack;
          usersId.unshift(userVideoTrack.jitsiTrack.getSourceName());
        } else {
          track = null;
        }

        if (screenSharedJid) {
          const desktopTrackKey = getTrackKey(screenSharedJid, 'desktop');
          const userDesktopTrack = participantsTracks[desktopTrackKey];

          if (userDesktopTrack) {
            usersId.unshift(userDesktopTrack.jitsiTrack.getSourceName());
          }
        }
      }
    } else if (
      (recentUnmutedUsers[0] && recentUnmutedUsers[0].audio === true) ||
      (recentUnmutedUsers[0]?.jid === localUser?.jid &&
        localUser?.audio === true)
    ) {
      if (recentUnmutedUsers[0]?.jid === localUser?.jid) {
        track = videoTrack;

        if (screenSharedJid) {
          const desktopTrackKey = getTrackKey(screenSharedJid, 'desktop');
          const userDesktopTrack = participantsTracks[desktopTrackKey];

          if (userDesktopTrack) {
            usersId.unshift(userDesktopTrack.jitsiTrack.getSourceName());
          }
        }
      } else {
        let id = recentUnmutedUsers[0].jid;
        tmpParticipant = participantsJID[id];
        const videoTrackKey = getTrackKey(id, 'video');
        const userVideoTrack = participantsTracks[videoTrackKey];

        if (userVideoTrack?.muted === false) {
          track = userVideoTrack;
          usersId.unshift(userVideoTrack.jitsiTrack.getSourceName());
        } else {
          track = null;
        }

        if (screenSharedJid) {
          const desktopTrackKey = getTrackKey(screenSharedJid, 'desktop');
          const userDesktopTrack = participantsTracks[desktopTrackKey];

          if (userDesktopTrack) {
            usersId.unshift(userDesktopTrack.jitsiTrack.getSourceName());
          }
        }
      }
    } else {
      track = null;
      if (screenSharedJid) {
        const desktopTrackKey = getTrackKey(screenSharedJid, 'desktop');
        const userDesktopTrack = participantsTracks[desktopTrackKey];

        if (userDesktopTrack) {
          usersId.unshift(userDesktopTrack.jitsiTrack.getSourceName());
        }
      }
    }
  } else {
    if (dominantSpeaker === null || dominantSpeaker === localUser.jid) {
      track = videoTrack;
      if (screenSharedJid) {
        const desktopTrackKey = getTrackKey(screenSharedJid, 'desktop');
        const userDesktopTrack = participantsTracks[desktopTrackKey];
        if (userDesktopTrack) {
          usersId.unshift(userDesktopTrack.jitsiTrack.getSourceName());
        }
      }
    } else if (participantsJID[dominantSpeaker]) {
      let id = dominantSpeaker;
      tmpParticipant = participantsJID[id];
      const videoTrackKey = getTrackKey(id, 'video');
      const userVideoTrack = participantsTracks[videoTrackKey];

      if (userVideoTrack?.muted === false) {
        track = userVideoTrack;
        usersId.unshift(userVideoTrack.jitsiTrack.getSourceName());
      } else {
        track = null;
      }

      if (screenSharedJid) {
        const desktopTrackKey = getTrackKey(screenSharedJid, 'desktop');
        const userDesktopTrack = participantsTracks[desktopTrackKey];

        if (userDesktopTrack) {
          usersId.unshift(userDesktopTrack.jitsiTrack.getSourceName());
        }
      }
    }
  }

  changeTrackResolutionSmallActive(usersId);

  if (track) {
    // console.log('_currentVideoTracks', _currentVideoTracks, track);
    _currentVideoTracks[track.participantId] = track;
    dispatch(setCurrentActiveVideoTracks(_currentVideoTracks));
  }

  activeSpeakerParticipant = {
    track: track,
    name: tmpParticipant.name,
    profile_image: tmpParticipant.profile_image,
    jid: tmpParticipant.jid,
    audio: tmpParticipant.audio,
    local: tmpParticipant?.jid === localUser?.jid,
  };

  dispatch(setActiveParticipant(activeSpeakerParticipant));
};

export const setActiveSpeakerParticipant = () => {
  const {dispatch} = store;
  const state = store.getState();
  const {
    roomDetail,
    localUser,
    participantsJID,
    localVideoTrack,
    dominantSpeaker,
    recentActiveSpeaker,
    recentUnmutedUsers,
    unmutedParticipantIds,
    participantsTracks,
  } = state.conf;

  const videoTrack = localUser.video ? getTrack(localVideoTrack) : null;
  let track = videoTrack;
  let tmpParticipant = localUser;
  const hideMutedAttendees = roomDetail?.hide_muted_attendees;
  const usersId = [];
  let activeSpeakerParticipant = {};
  let _currentVideoTracks = [];

  unmutedParticipantIds
    .filter(id => id !== dominantSpeaker)
    .forEach(jid => {
      const videoTrackKey = getTrackKey(jid, 'video');
      const userVideoTrack = participantsTracks[videoTrackKey];

      if (userVideoTrack?.muted === false) {
        usersId.push(userVideoTrack.jitsiTrack.getSourceName());
      }
    });

  if (hideMutedAttendees) {
    if (
      (participantsJID[dominantSpeaker] &&
        participantsJID[dominantSpeaker]?.audio === true) ||
      (dominantSpeaker === localUser?.jid && localUser?.audio === true)
    ) {
      if (dominantSpeaker === localUser?.jid) {
        track = videoTrack;
      } else {
        let id = dominantSpeaker;
        tmpParticipant = participantsJID[id];
        const videoTrackKey = getTrackKey(id, 'video');
        const userVideoTrack = participantsTracks[videoTrackKey];

        if (userVideoTrack?.muted === false) {
          track = userVideoTrack;
          usersId.unshift(userVideoTrack.jitsiTrack.getSourceName());
        } else {
          track = null;
        }
      }
    } else if (
      (participantsJID[recentActiveSpeaker[0]] &&
        participantsJID[recentActiveSpeaker[0]].audio === true) ||
      (recentActiveSpeaker[0] === localUser?.jid && localUser?.audio === true)
    ) {
      if (recentActiveSpeaker[0] === localUser?.jid) {
        track = videoTrack;
      } else {
        let id = recentActiveSpeaker[0];
        tmpParticipant = participantsJID[id];
        const videoTrackKey = getTrackKey(id, 'video');
        const userVideoTrack = participantsTracks[videoTrackKey];

        if (userVideoTrack?.muted === false) {
          track = userVideoTrack;
          usersId.unshift(userVideoTrack.jitsiTrack.getSourceName());
        } else {
          track = null;
        }
      }
    } else if (
      (recentUnmutedUsers[0] && recentUnmutedUsers[0].audio === true) ||
      (recentUnmutedUsers[0]?.jid === localUser?.jid &&
        localUser?.audio === true)
    ) {
      if (recentUnmutedUsers[0]?.jid === localUser?.jid) {
        track = videoTrack;
      } else {
        let id = recentUnmutedUsers[0].jid;
        tmpParticipant = recentUnmutedUsers[0];
        const videoTrackKey = getTrackKey(id, 'video');
        const userVideoTrack = participantsTracks[videoTrackKey];

        if (userVideoTrack?.muted === false) {
          track = userVideoTrack;
          usersId.unshift(userVideoTrack.jitsiTrack.getSourceName());
        } else {
          track = null;
        }
      }
    } else {
      track = null;
    }
  } else {
    if (dominantSpeaker === null || dominantSpeaker === localUser.jid) {
      track = videoTrack;
    } else if (participantsJID[dominantSpeaker]) {
      let id = dominantSpeaker;
      tmpParticipant = participantsJID[dominantSpeaker];
      const videoTrackKey = getTrackKey(id, 'video');
      const userVideoTrack = participantsTracks[videoTrackKey];

      if (userVideoTrack?.muted === false) {
        track = userVideoTrack;
        usersId.unshift(userVideoTrack.jitsiTrack.getSourceName());
      } else {
        track = null;
      }
    }
  }

  changeTrackResolutionActiveSpeaker(usersId);

  if (track) {
    _currentVideoTracks.push(track);
    dispatch(setCurrentActiveVideoTracks(_currentVideoTracks));
  }

  activeSpeakerParticipant = {
    track: track,
    name: tmpParticipant.name,
    profile_image: tmpParticipant.profile_image,
    jid: tmpParticipant.jid,
    audio: tmpParticipant.audio,
    local: tmpParticipant?.jid === localUser?.jid,
  };
  dispatch(setActiveParticipant(activeSpeakerParticipant));
};

export const sendActiveSpeakerRequest = (keepAliveRequest = false) => {
  const state = store.getState();
  const {activeParticipant, participantsTracks} = state.conf;
  const activeSpeakerParticipant = {...activeParticipant};
  const usersId = [];
  if (activeSpeakerParticipant) {
    let trackKey = getTrackKey(activeParticipant.jid, 'video');
    const remoteVideoTrack = participantsTracks[trackKey];
    if (remoteVideoTrack?.muted === false) {
      usersId.unshift(remoteVideoTrack.jitsiTrack.getSourceName());
    }
  }

  changeTrackResolutionActiveSpeaker(usersId, keepAliveRequest);
};

export const sendActiveSpeakerRequestForSmallWindow = (
  keepAliveRequest = false,
) => {
  const state = store.getState();
  const {activeParticipant, participantsTracks, screenSharedJid} = state.conf;
  const activeSpeakerParticipant = {...activeParticipant};
  const usersId = [];
  if (activeSpeakerParticipant) {
    let trackKey = getTrackKey(activeParticipant.jid, 'video');
    const remoteVideoTrack = participantsTracks[trackKey];
    if (remoteVideoTrack?.muted === false) {
      usersId.unshift(remoteVideoTrack.jitsiTrack.getSourceName());
    }
  }

  if (screenSharedJid) {
    const desktopTrackKey = getTrackKey(screenSharedJid, 'desktop');
    const userDesktopTrack = participantsTracks[desktopTrackKey];

    if (userDesktopTrack) {
      usersId.unshift(userDesktopTrack.jitsiTrack.getSourceName());
    }
  }

  changeTrackResolutionSmallActive(usersId, keepAliveRequest);
};

export const setActiveTracks = _isUpdateActiveParticipant => {
  const {dispatch} = store;
  const state = store.getState();
  const {activeParticipant, participantsTracks} = state.conf;
  const activeSpeakerParticipant = {...activeParticipant};

  if (activeSpeakerParticipant && activeSpeakerParticipant?.track === null) {
    let trackKey = getTrackKey(activeParticipant.jid, 'video');
    const remoteVideoTrack = participantsTracks[trackKey];

    if (remoteVideoTrack?.muted === false) {
      activeSpeakerParticipant.track = remoteVideoTrack;
      dispatch(setActiveParticipant(activeSpeakerParticipant));
    }
  }
};

export const setActiveExcludingYourselfParticipant = () => {
  const {dispatch} = store;
  const state = store.getState();
  const {
    roomDetail,
    localUser,
    participantsJID,
    dominantSpeaker,
    recentActiveSpeaker,
    recentUnmutedUsers,
    participantsTracks,
  } = state.conf;
  const hideMutedAttendees = roomDetail?.hide_muted_attendees;
  let track = null;
  let tmpParticipant = {};
  let activeSpeakerParticipant = {};
  let _currentVideoTracks = [];

  const usersId = [];
  const tmpRecentActiveSpeaker = recentActiveSpeaker.filter(
    item => item !== localUser?.jid,
  );
  const tmpRecentUnmutedUsers = recentUnmutedUsers.filter(
    item => item.jid !== localUser?.jid,
  );

  if (hideMutedAttendees) {
    if (
      participantsJID[dominantSpeaker] &&
      participantsJID[dominantSpeaker].audio === true
    ) {
      let id = dominantSpeaker;
      tmpParticipant = participantsJID[id];
      const videoTrackKey = getTrackKey(id, 'video');
      const userVideoTrack = participantsTracks[videoTrackKey];

      if (userVideoTrack?.muted === false) {
        track = userVideoTrack;
        usersId.unshift(userVideoTrack.jitsiTrack.getSourceName());
      } else {
        track = null;
      }
    } else if (
      participantsJID[tmpRecentActiveSpeaker[0]] &&
      participantsJID[tmpRecentActiveSpeaker[0]].audio === true
    ) {
      let id = tmpRecentActiveSpeaker[0];
      tmpParticipant = participantsJID[id];
      const videoTrackKey = getTrackKey(id, 'video');
      const userVideoTrack = participantsTracks[videoTrackKey];

      if (userVideoTrack?.muted === false) {
        track = userVideoTrack;
        usersId.unshift(userVideoTrack.jitsiTrack.getSourceName());
      } else {
        track = null;
      }
    } else if (tmpRecentUnmutedUsers[0]) {
      let id = tmpRecentUnmutedUsers[0].jid;
      tmpParticipant = participantsJID[id];
      const videoTrackKey = getTrackKey(id, 'video');
      const userVideoTrack = participantsTracks[videoTrackKey];

      if (userVideoTrack?.muted === false) {
        track = userVideoTrack;
        usersId.unshift(userVideoTrack.jitsiTrack.getSourceName());
      } else {
        track = null;
      }
    } else {
      track = null;
    }
  } else {
    if (participantsJID[dominantSpeaker]) {
      let id = dominantSpeaker;
      tmpParticipant = participantsJID[id];
      const videoTrackKey = getTrackKey(id, 'video');
      const userVideoTrack = participantsTracks[videoTrackKey];

      if (userVideoTrack?.muted === false) {
        track = userVideoTrack;
        usersId.unshift(userVideoTrack.jitsiTrack.getSourceName());
      } else {
        track = null;
      }
    } else if (participantsJID[tmpRecentActiveSpeaker[0]]) {
      let id = tmpRecentActiveSpeaker[0];
      tmpParticipant = participantsJID[id];
      const videoTrackKey = getTrackKey(id, 'video');
      const userVideoTrack = participantsTracks[videoTrackKey];

      if (userVideoTrack?.muted === false) {
        track = userVideoTrack;
        usersId.unshift(userVideoTrack.jitsiTrack.getSourceName());
      } else {
        track = null;
      }
    }
  }

  changeTrackResolutionActiveSpeaker(usersId);

  if (track) {
    _currentVideoTracks.push(track);
    dispatch(setCurrentActiveVideoTracks(_currentVideoTracks));
  }

  activeSpeakerParticipant = {
    track: track,
    name: tmpParticipant.name,
    profile_image: tmpParticipant.profile_image,
    jid: tmpParticipant.jid,
    audio: tmpParticipant.audio,
    local: tmpParticipant?.jid === localUser?.jid,
  };
  dispatch(setActiveParticipant(activeSpeakerParticipant));
};

export const setPresentationTracks = (
  _isUpdatePresentation,
  keepAliveRequest = false,
) => {
  const {dispatch} = store;
  const state = store.getState();
  const {
    participantsJID,
    presentationParticipant,
    mediaShareparticipantsJID,
    screenSharedJid,
    participantsTracks,
  } = state.conf;
  const _presentationParticipant = {...presentationParticipant};

  let track = null;
  let usersId = [];
  const participant = participantsJID[screenSharedJid];
  if (participant) {
    let trackKey = getTrackKey(screenSharedJid, 'desktop');
    const remoteDesktopTrack = participantsTracks[trackKey];
    if (remoteDesktopTrack?.muted === false) {
      usersId.push(remoteDesktopTrack?.jitsiTrack?.getSourceName());
      track = remoteDesktopTrack;
    }
  } else if (
    mediaShareparticipantsJID &&
    mediaShareparticipantsJID === screenSharedJid
  ) {
    let trackKey = getTrackKey(screenSharedJid, 'video');
    const remoteDesktopTrack = participantsTracks[trackKey];

    if (remoteDesktopTrack?.muted === false) {
      usersId.push(remoteDesktopTrack.jitsiTrack.getSourceName());
      track = remoteDesktopTrack;
    }
  }

  if (track) {
    if (_presentationParticipant && _presentationParticipant?.track === null) {
      changeTrackResolutionPresentation(usersId, keepAliveRequest);
      _presentationParticipant.track = track;
      if (_isUpdatePresentation) {
        dispatch(setPresentationParticipant(_presentationParticipant));
      }
    }
  }
};

export const setPresentationViewParticipant = showActiveWindow => {
  const {dispatch} = store;
  const state = store.getState();
  const {
    participantsJID,
    screenSharedJid,
    mediaShareparticipantsJID,
    participantsTracks,
  } = state.conf;
  let track = null;
  let _currentVideoTracks = [];
  let user_id = '';

  const participant = participantsJID[screenSharedJid];
  if (participant) {
    const desktopTrackKey = getTrackKey(screenSharedJid, 'desktop');
    const userDesktopTrack = participantsTracks[desktopTrackKey];
    if (userDesktopTrack?.muted === false) {
      track = userDesktopTrack;
    }
    user_id = screenSharedJid;
  } else if (
    mediaShareparticipantsJID &&
    mediaShareparticipantsJID === screenSharedJid
  ) {
    const videoTrackKey = getTrackKey(screenSharedJid, 'video');
    const userVideoTrack = participantsTracks[videoTrackKey];

    if (userVideoTrack?.muted === false) {
      track = userVideoTrack;
    }
    user_id = screenSharedJid;
  }

  if (track) {
    if (!showActiveWindow) {
      changeTrackResolutionPresentation([track.jitsiTrack.getSourceName()]);
    }
    _currentVideoTracks.push(track);
    dispatch(setCurrentActiveVideoTracks(_currentVideoTracks));
  }

  const presentationParticipant = {
    track: track,
    name: participant?.name,
    jid: user_id,
    local: false,
  };

  dispatch(setPresentationParticipant(presentationParticipant));
};
